
import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Star, Clock, Users, Search, ChevronDown, MessageSquare, Plus, Edit2, Check, GripVertical, Trash2, DollarSign, Calendar, ChevronLeft, ChevronRight } from 'lucide-react'

const generateId = () => Math.random().toString(36).substr(2, 9)

const statusOptions = [
  { value: 'Done', color: 'bg-green-500' },
  { value: 'Pending', color: 'bg-yellow-500' },
]

const priorityOptions = [
  { value: 'Alto', color: 'bg-red-500' },
  { value: 'Medio', color: 'bg-yellow-500' },
  { value: 'Bajo', color: 'bg-blue-500' },
]

export default function TaskManager() {
  const [boards, setBoards] = useState([
    {
      id: 'board1',
      title: 'To Do',
      tasks: [
        { id: generateId(), task: 'Task 1', status: 'Pending', person: 'John', startDate: '2023-06-01', endDate: '2023-06-05', priority: 'Bajo' },
        { id: generateId(), task: 'Task 2', status: 'Done', person: 'Jane', startDate: '2023-06-02', endDate: '2023-06-02', priority: 'Medio' },
      ],
      isEditing: false,
      newTaskContent: '',
    },
    {
      id: 'board2',
      title: 'In Progress',
      tasks: [
        { id: generateId(), task: 'Task 3', status: 'Pending', person: 'Bob', startDate: '2023-06-03', endDate: '2023-06-10', priority: 'Alto' },
      ],
      isEditing: false,
      newTaskContent: '',
    },
    {
      id: 'board3',
      title: 'Done',
      tasks: [],
      isEditing: false,
      newTaskContent: '',
    },
  ])

  const [registers, setRegisters] = useState([
    { 
      id: 'reg1', 
      task: 'Register Task 1', 
      status: 'Done', 
      person: 'Alice', 
      startDate: '2023-06-04',
      endDate: '2023-06-08',
      priority: 'Medio'
    },
    { 
      id: 'reg2', 
      task: 'Register Task 2', 
      status: 'Pending', 
      person: 'Charlie', 
      startDate: '2023-06-05',
      endDate: '2023-06-05',
      priority: 'Bajo'
    },
  ])

  const [newRegisterTask, setNewRegisterTask] = useState('')
  const [openStatusDropdown, setOpenStatusDropdown] = useState(null)
  const [openPriorityDropdown, setOpenPriorityDropdown] = useState(null)
  const [view, setView] = useState('main') // 'main' or 'gantt'
  const [currentDate, setCurrentDate] = useState(new Date())

  const onDragEnd = (result) => {
    const { source, destination, type } = result

    if (!destination) return

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return
    }

    if (type === 'board') {
      const newBoards = Array.from(boards)
      const [reorderedBoard] = newBoards.splice(source.index, 1)
      newBoards.splice(destination.index, 0, reorderedBoard)
      setBoards(newBoards)
      return
    }

    if (source.droppableId === 'registers') {
      // Moving from registers to a board
      const sourceRegisters = Array.from(registers)
      const [movedRegister] = sourceRegisters.splice(source.index, 1)
      setRegisters(sourceRegisters)

      setBoards(prevBoards => prevBoards.map(board => {
        if (board.id === destination.droppableId) {
          const newTasks = Array.from(board.tasks)
          newTasks.splice(destination.index, 0, movedRegister)
          return { ...board, tasks: newTasks }
        }
        return board
      }))
    } else {
      // Moving between boards or within the same board
      setBoards(prevBoards => {
        const newBoards = prevBoards.map(board => {
          if (board.id === source.droppableId) {
            const newTasks = Array.from(board.tasks)
            const [movedTask] = newTasks.splice(source.index, 1)
            if (board.id === destination.droppableId) {
              // Moving within the same board
              newTasks.splice(destination.index, 0, movedTask)
            }
            return { ...board, tasks: newTasks }
          }
          if (board.id === destination.droppableId && board.id !== source.droppableId) {
            // Moving to a different board
            const newTasks = Array.from(board.tasks)
            const sourceBoard = prevBoards.find(b => b.id === source.droppableId)
            const movedTask = sourceBoard.tasks[source.index]
            newTasks.splice(destination.index, 0, movedTask)
            return { ...board, tasks: newTasks }
          }
          return board
        })
        return newBoards
      })
    }
  }

  const addNewTask = (boardId) => {
    setBoards(prevBoards => prevBoards.map(board => {
      if (board.id === boardId) {
        if (!board.newTaskContent.trim()) return board
        const today = new Date().toISOString().split('T')[0]
        return {
          ...board,
          tasks: [...board.tasks, { 
            id: generateId(), 
            task: board.newTaskContent, 
            status: 'Pending', 
            person: '', 
            startDate: today, 
            endDate: today,
            priority: 'Bajo'
          }],
          newTaskContent: '',
        }
      }
      return board
    }))
  }

  const updateNewTaskContent = (boardId, content) => {
    setBoards(prevBoards => prevBoards.map(board => 
      board.id === boardId ? { ...board, newTaskContent: content } : board
    ))
  }

  const toggleEditMode = (boardId) => {
    setBoards(boards.map(board => 
      board.id === boardId ? { ...board, isEditing: !board.isEditing } : board
    ))
  }

  const updateBoardTitle = (boardId, newTitle) => {
    setBoards(boards.map(board => 
      board.id === boardId ? { ...board, title: newTitle } : board
    ))
  }

  const finishEditingBoardTitle = (boardId) => {
    setBoards(boards.map(board => 
      board.id === boardId ? { ...board, isEditing: false } : board
    ))
  }

  const handleKeyDown = (e, boardId) => {
    if (e.key === 'Enter') {
      finishEditingBoardTitle(boardId)
    }
  }

  const addNewBoard = () => {
    const newBoard = {
      id: generateId(),
      title: "Nuevo tablero",
      tasks: [],
      isEditing: false,
      newTaskContent: '',
    }
    setBoards([...boards, newBoard])
  }

  const addNewRegister = () => {
    if (!newRegisterTask.trim()) return
    const today = new Date().toISOString().split('T')[0]
    const newRegister = {
      id: generateId(),
      task: newRegisterTask,
      status: 'Pending',
      person: '',
      startDate: today,
      endDate: today,
      priority: 'Bajo'
    }
    setRegisters([...registers, newRegister])
    setNewRegisterTask('')
  }

  const deleteBoard = (boardId) => {
    setBoards(boards.filter(board => board.id !== boardId))
  }

  const toggleStatusDropdown = (taskId) => {
    setOpenStatusDropdown(openStatusDropdown === taskId ? null : taskId)
    setOpenPriorityDropdown(null)
  }

  const togglePriorityDropdown = (taskId) => {
    setOpenPriorityDropdown(openPriorityDropdown === taskId ? null : taskId)
    setOpenStatusDropdown(null)
  }

  const updateTaskStatus = (boardId, taskId, newStatus) => {
    setBoards(prevBoards => prevBoards.map(board => {
      if (board.id === boardId) {
        return {
          ...board,
          tasks: board.tasks.map(task => 
            task.id === taskId ? { ...task, status: newStatus } : task
          )
        }
      }
      return board
    }))
    setOpenStatusDropdown(null)
  }

  const updateTaskPriority = (boardId, taskId, newPriority) => {
    setBoards(prevBoards => prevBoards.map(board => {
      if (board.id === boardId) {
        return {
          ...board,
          tasks: board.tasks.map(task => 
            task.id === taskId ? { ...task, priority: newPriority } : task
          )
        }
      }
      return board
    }))
    setOpenPriorityDropdown(null)
  }

  const renderGanttChart = () => {
    const allTasks = boards.flatMap(board => 
      board.tasks.map(task => ({ ...task, boardName: board.title }))
    ).concat(registers.map(register => ({ ...register, boardName: 'Registers' })))
    
    // Sort tasks by start date
    allTasks.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
    const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
    
    const daysInMonth = endOfMonth.getDate()
  
    const getMonthName = (date) => {
      return date.toLocaleString('default', { month: 'long', year: 'numeric' })
    }
  
    const goToPreviousMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))
    }
  
    const goToNextMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))
    }

    // Group tasks by start date
    const tasksByDate = allTasks.reduce((acc, task) => {
      const date = task.startDate
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(task)
      return acc
    }, {})
  
    return (
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="flex justify-between items-center mb-4">
          <button onClick={goToPreviousMonth} className="p-2 rounded-full hover:bg-gray-200">
            <ChevronLeft className="w-6 h-6" />
          </button>
          <h2 className="text-xl font-bold">{getMonthName(currentDate)}</h2>
          <button onClick={goToNextMonth} className="p-2 rounded-full hover:bg-gray-200">
            <ChevronRight className="w-6 h-6" />
          </button>
        </div>
        <div className="overflow-x-auto">
          <div className="min-w-full">
            <div className="flex">
              <div className="w-48 flex-shrink-0 font-bold p-2 border-r">Task</div>
              <div className="flex-grow flex">
                {Array.from({ length: daysInMonth }).map((_, index) => {
                  const date = new Date(startOfMonth)
                  date.setDate(index + 1)
                  return (
                    <div key={index} className={`flex-grow text-center text-xs border-r ${date.getDay() === 0 || date.getDay() === 6 ? 'bg-gray-100' : ''}`}>
                      {index + 1}
                    </div>
                  )
                })}
              </div>
            </div>
            {Object.entries(tasksByDate).map(([date, tasks]) => (
              <div key={date} className="flex items-start hover:bg-gray-50">
                <div className="w-48 flex-shrink-0 p-2 border-r truncate">
                  {tasks.map(task => (
                    <div key={task.id} className="text-xs mb-1">{task.task}</div>
                  ))}
                </div>
                <div className="flex-grow flex items-start relative">
                  {tasks.map((task, taskIndex) => {
                    const taskStart = new Date(task.startDate)
                    const taskEnd = new Date(task.endDate)
                    
                    // Check if the task is within the current month
                    if (taskStart > endOfMonth || taskEnd < startOfMonth) return null
        
                    const offsetDays = Math.max(0, Math.floor((taskStart - startOfMonth) / (1000 * 60 * 60 * 24)))
                    const duration = Math.min(daysInMonth - offsetDays, Math.ceil((taskEnd - taskStart) / (1000 * 60 * 60 * 24)) + 1)
        
                    return (
                      <div 
                        key={task.id}
                        className={`absolute h-5 rounded flex items-center justify-center text-xs text-white ${
                          task.status === 'Done' ? 'bg-green-500' : 
                          task.priority === 'Alto' ? 'bg-red-500' :
                          task.priority === 'Medio' ? 'bg-yellow-500' : 'bg-blue-500'
                        } group`}
                        style={{ 
                          left: `${(offsetDays / daysInMonth) * 100}%`,
                          width: `${(duration / daysInMonth) * 100}%`,
                          top: `${taskIndex * 24}px`
                        }}
                      >
                        <span className="z-10 truncate">{task.person}</span>
                        <div className="absolute invisible group-hover:visible bg-gray-800 text-white p-2 rounded shadow-lg -top-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                          {task.boardName}: {task.task}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-2">
          <h1 className="text-2xl font-bold">Empresa</h1>
          <Clock className="w-5 h-5 text-gray-500" />
          <Star className="w-5 h-5 text-yellow-400" />
        </div>
        <div className="flex items-center space-x-4">
          <span className="flex items-center">
            <Clock className="w-4 h-4 mr-1" />
            Last seen
            <div className="w-6 h-6 rounded-full bg-gray-300 ml-1"></div>
            <span className="text-gray-600 ml-1">+2</span>
          </span>
          <span className="flex items-center">
            <Users className="w-4 h-4 mr-1" />
            Invite / 1
          </span>
        </div>
      </div>

      <div className="flex space-x-4 mb-4">
        <button 
          className={`px-3 py-1 rounded ${view === 'main' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setView('main')}
        >
          Main Table
        </button>
        <button 
          className={`px-3 py-1 rounded ${view === 'gantt' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setView('gantt')}
        >
          Gantt Chart
        </button>
        <button className="px-3 py-1 bg-gray-200 rounded">Dashboard</button>
      </div>

      {view === 'main' ? (
        
    <DragDropContext onDragEnd={onDragEnd}>
    <div className="flex space-x-4">
        <div className="w-3/4">
        <Droppable droppableId="boards" type="board">
            {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                {boards.map((board, index) => (
                <Draggable key={board.id} draggableId={board.id} index={index}>
                    {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`bg-white rounded-lg shadow-md p-4 ${snapshot.isDragging ? 'border-2 border-blue-500' : ''}`}
                    >
                        <div className="flex justify-between items-center mb-2">
                        <div {...provided.dragHandleProps} className="flex items-center cursor-move">
                            <GripVertical className="w-5 h-5 mr-2 text-gray-400" />
                            {board.isEditing ? (
                            <input
                                type="text"
                                value={board.title}
                                onChange={(e) => updateBoardTitle(board.id, e.target.value)}
                                onBlur={() => finishEditingBoardTitle(board.id)}
                                onKeyDown={(e) => handleKeyDown(e, board.id)}
                                className="font-semibold border rounded px-2 py-1"
                                autoFocus
                            />
                            ) : (
                            <h2 className="text-lg font-semibold">{board.title}</h2>
                            )}
                        </div>
                        <div className="flex items-center space-x-2">
                            <button
                            className="text-gray-600 hover:text-gray-900"
                            onClick={() => toggleEditMode(board.id)}
                            >
                            {board.isEditing ? <Check className="w-4 h-4" /> : <Edit2 className="w-4 h-4" />}
                            </button>
                            <button
                            className="text-red-600 hover:text-red-800"
                            onClick={() => deleteBoard(board.id)}
                            >
                            <Trash2 className="w-4 h-4" />
                            </button>
                        </div>
                        </div>
                        <Droppable droppableId={board.id} type="task">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                            <table className="w-full border-collapse">
                                <thead>
                                <tr className="bg-gray-100">
                                    <th className="border p-2"></th>
                                    <th className="border p-2 text-left">Task</th>
                                    <th className="border p-2">Status</th>
                                    <th className="border p-2">Person</th>
                                    <th className="border p-2">Start Date</th>
                                    <th className="border p-2">End Date</th>
                                    <th className="border p-2">Priority</th>
                                    <th className="border p-2"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {board.tasks.map((task, index) => (
                                    <Draggable key={task.id} draggableId={task.id} index={index}>
                                    {(provided, snapshot) => (
                                        <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        className={`hover:bg-gray-50 ${snapshot.isDragging ? 'bg-blue-100' : ''}`}
                                        >
                                        <td className="border p-2">
                                            <input type="checkbox" />
                                        </td>
                                        <td className="border p-2 flex items-center">
                                            <span {...provided.dragHandleProps}>
                                            <GripVertical className="w-4 h-4 mr-2 text-gray-400 cursor-move" />
                                            </span>
                                            {task.task}
                                            <MessageSquare className="w-4 h-4 ml-2 text-gray-400" />
                                        </td>
                                        <td className="border p-2 relative">
                                            <button
                                            className={`w-full text-left px-2 py-1 rounded ${statusOptions.find(option => option.value === task.status)?.color || 'bg-gray-300'}`}
                                            onClick={() => toggleStatusDropdown(task.id)}
                                            >
                                            {task.status || 'Set status'}
                                            </button>
                                            {openStatusDropdown === task.id && (
                                            <div className="absolute z-10 mt-1 w-full bg-white border rounded shadow-lg">
                                                {statusOptions.map((option) => (
                                                <button
                                                    key={option.value}
                                                    className={`w-full text-left px-2 py-1 hover:bg-gray-100 ${option.color}`}
                                                    onClick={() => updateTaskStatus(board.id, task.id, option.value)}
                                                >
                                                    {option.value}
                                                </button>
                                                ))}
                                            </div>
                                            )}
                                        </td>
                                        <td className="border p-2">{task.person}</td>
                                        <td className="border p-2">{task.startDate}</td>
                                        <td className="border p-2">
                                            {task.startDate === task.endDate ? (
                                            <span className="text-gray-500">No end date</span>
                                            ) : (
                                            task.endDate
                                            )}
                                        </td>
                                        <td className="border p-2 relative">
                                            <button
                                            className={`w-full text-left px-2 py-1 rounded ${priorityOptions.find(option => option.value === task.priority)?.color || 'bg-gray-300'}`}
                                            onClick={() => togglePriorityDropdown(task.id)}
                                            >
                                            {task.priority || 'Set priority'}
                                            </button>
                                            {openPriorityDropdown === task.id && (
                                            <div className="absolute z-10 mt-1 w-full bg-white border rounded shadow-lg">
                                                {priorityOptions.map((option) => (
                                                <button
                                                    key={option.value}
                                                    className={`w-full text-left px-2 py-1 hover:bg-gray-100 ${option.color}`}
                                                    onClick={() => updateTaskPriority(board.id, task.id, option.value)}
                                                >
                                                    {option.value}
                                                </button>
                                                ))}
                                            </div>
                                            )}
                                        </td>
                                        <td className="border p-2">
                                            <Plus className="w-4 h-4 text-gray-400" />
                                        </td>
                                        </tr>
                                    )}
                                    </Draggable>
                                ))}
                                </tbody>
                            </table>
                            {provided.placeholder}
                            </div>
                        )}
                        </Droppable>
                        <div className="mt-2 flex space-x-2">
                        <input
                            type="text"
                            placeholder="+ Add Task"
                            value={board.newTaskContent}
                            onChange={(e) => updateNewTaskContent(board.id, e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && addNewTask(board.id)}
                            className="flex-grow border rounded px-2 py-1"
                        />
                        <button
                            onClick={() => addNewTask(board.id)}
                            className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
                        >
                            Add
                        </button>
                        </div>
                    </div>
                    )}
                </Draggable>
                ))}
                {provided.placeholder}
            </div>
            )}
        </Droppable>

        <div className="mt-4">
            <button
            onClick={addNewBoard}
            className="bg-green-500 text-white p-2 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            aria-label="Add new board"
            >
            <Plus className="w-6 h-6" />
            </button>
        </div>
        </div>

        <div className="w-1/4">
        <div className="bg-white rounded-lg shadow-md p-4">
            <h2 className="text-lg font-semibold mb-4">Registers</h2>
            <Droppable droppableId="registers" type="task">
            {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                {registers.map((register, index) => (
                    <Draggable key={register.id} draggableId={register.id} index={index}>
                    {(provided, snapshot) => (
                        <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`bg-gray-50 p-3 rounded-lg ${snapshot.isDragging ? 'border-2 border-blue-500' : ''}`}
                        >
                        <div {...provided.dragHandleProps} className="cursor-move">
                            <GripVertical className="w-4 h-4 mb-2 text-gray-400" />
                        </div>
                        <div className="space-y-1">
                            <div><strong>Task:</strong> {register.task}</div>
                            <div><strong>Status:</strong> {register.status}</div>
                            <div><strong>Person:</strong> {register.person}</div>
                            <div><strong>Start Date:</strong> {register.startDate}</div>
                            <div><strong>End Date:</strong> {
                            register.startDate === register.endDate ? 
                            <span className="text-gray-500">No end date</span> : 
                            register.endDate
                            }</div>
                            <div><strong>Priority:</strong> {register.priority}</div>
                        </div>
                        </div>
                    )}
                    </Draggable>
                ))}
                {provided.placeholder}
                </div>
            )}
            </Droppable>
            <div className="mt-4 flex space-x-2">
            <input
                type="text"
                placeholder="New register task"
                value={newRegisterTask}
                onChange={(e) => setNewRegisterTask(e.target.value)}
                className="flex-grow border rounded px-2 py-1"
            />
            <button
                onClick={addNewRegister}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            >
                Add Register
            </button>
            </div>
        </div>
        </div>
    </div>
    </DragDropContext> 
      ) : (
        renderGanttChart()
      )}
    </div>
  )
}