
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect } from "react";
import { gapi } from 'gapi-script';
import jwt_decode from "jwt-decode";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";
import dotenv from "dotenv";
dotenv.config();

export default function LoginButton() {
  localStorage.clear();
  const url = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const clientID = "244133301755-gios5i53e511q5el6qrjidgdvddme0ud.apps.googleusercontent.com"
  const onSuccess = (res) => {
    var decodedResponse = jwt_decode(res.credential);
    const mail = decodedResponse.email;
    fetch(`${url}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "email": mail
      }
      )
    })
      .then(data => {
        if (data.status === 200) {
          navigate('/dashboard/app');
        }
        else {
          data.json().then(data => alert(data.error));
        }
      })

    const header = {
      "alg": "HS256",
      "typ": "JWT"
    }
    const data = {
      "name": decodedResponse.family_name,
      "lastname": decodedResponse.given_name,
      "email": decodedResponse.email,
      "empresa": "Vina Errazuriz"
    }
    const secret = "123123123123123";
    const EncodedData = {
      Base64Header: Buffer.from(JSON.stringify(header)).toString('base64'),
      Base64Data: Buffer.from(JSON.stringify(data)).toString('base64'),
      Base64secret: Buffer.from(secret).toString('base64')
    }
    let token = EncodedData.Base64Header + "." + EncodedData.Base64Data + "." + EncodedData.Base64secret;

    localStorage.setItem('jwt', JSON.stringify(token));
  }
  const onFailure = (res) => {
    console.log("Login Failed");
  }

  useEffect(() => {
    const start = () => {

      gapi.client.init({
        clientId: clientID,
        scope: 'profile',
        responseType: 'id_token'
      })
    };
    gapi.load('client:auth2', start);
  }, []);



  return (
    <GoogleOAuthProvider clientId={clientID}>
      <GoogleLogin
        clientId={clientID}
        buttonText="Login"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        isSignedIn={true}
        width={"350"}
      />
    </GoogleOAuthProvider>
  );
}
