import React from 'react'
import { ChevronLeft, ChevronRight, Calendar } from 'lucide-react'

const GanttChart = ({ currentDate, setCurrentDate, boards, registers }) => {
  const allTasks = boards.flatMap(board => 
    board.processes.flatMap(process => 
      process.tasks.map(task => ({ ...task, boardName: board.title, processName: process.title }))
    )
  ).concat(registers.map(register => ({ ...register, boardName: 'Registers', processName: 'Registers' })))
  
  allTasks.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
  
  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  
  const daysInMonth = endOfMonth.getDate()

  const getMonthName = (date) => {
    return date.toLocaleString('default', { month: 'long', year: 'numeric' })
  }

  const goToPreviousMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))
  }

  const goToNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))
  }

  // Group tasks by start date
  const tasksByDate = allTasks.reduce((acc, task) => {
    const date = task.startDate
    if (!acc[date]) {
      acc[date] = []
    }
    acc[date].push(task)
    return acc
  }, {})

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <button onClick={goToPreviousMonth} className="p-2 rounded-full hover:bg-gray-200">
          <ChevronLeft className="w-6 h-6" />
        </button>
        <h2 className="text-xl font-bold">{getMonthName(currentDate)}</h2>
        <button onClick={goToNextMonth} className="p-2 rounded-full hover:bg-gray-200">
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>
      <div className="overflow-x-auto">
        <div className="min-w-full">
          <div className="flex">
            <div className="w-48 flex-shrink-0 font-bold p-2 border-r">Task</div>
            <div className="flex-grow flex">
              {Array.from({ length: daysInMonth }).map((_, index) => {
                const date = new Date(startOfMonth)
                date.setDate(index + 1)
                return (
                  <div key={index} className={`flex-grow text-center text-xs border-r ${date.getDay() === 0 || date.getDay() === 6 ? 'bg-gray-100' : ''}`}>
                    {index + 1}
                  </div>
                )
              })}
            </div>
          </div>
          {Object.entries(tasksByDate).map(([date, tasks]) => (
            <div key={date} className="flex items-start hover:bg-gray-50">
              <div className="w-48 flex-shrink-0 p-2 border-r truncate">
                {tasks.map(task => (
                  <div key={task.id} className="text-xs mb-1">{task.task}</div>
                ))}
              </div>
              <div className="flex-grow flex items-start relative">
                {tasks.map((task, taskIndex) => {
                  const taskStart = new Date(task.startDate)
                  const taskEnd = new Date(task.endDate)
                  // Check if the task is within the current month
                  if (taskStart > endOfMonth || taskEnd < startOfMonth) return null
        
                  const offsetDays = Math.max(0, Math.floor((taskStart - startOfMonth) / (1000 * 60 * 60 * 24)))
                  const duration = Math.min(daysInMonth - offsetDays, Math.ceil((taskEnd - taskStart) / (1000 * 60 * 60 * 24)) + 1)
        
                  return (
                    <div 
                      key={task.id}
                      className={`absolute h-5 rounded flex items-center justify-center text-xs text-white ${
                        task.status === 'Done' ? 'bg-green-500' : 
                        task.priority === 'Alto' ? 'bg-red-500' :
                        task.priority === 'Medio' ? 'bg-yellow-500' : 'bg-blue-500'
                      } group`}
                      style={{ 
                        left: `${(offsetDays / daysInMonth) * 100}%`,
                          width: `${(duration / daysInMonth) * 100}%`,
                          top: `${taskIndex * 24}px`
                        }}
                      >
                        <span className="z-10 truncate">{task.person}</span>
                        <div className="absolute invisible group-hover:visible bg-gray-800 text-white p-2 rounded shadow-lg -top-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                          {task.boardName}: {task.task}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

export default GanttChart
