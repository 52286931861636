export function Button({ children, onClick, className = '', disabled }) {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        className={`px-4 py-2 text-white text-xs font-bold rounded  transition-colors ${className}`}
      >
        {children}
      </button>
    );
  }