// component
import { title } from 'process';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [

  {
    title: 'Dashboard',
    path: '/tasks/dashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Crear tarea',
    path: '/tasks/createTask',
    icon: icon('icon7'),
  },
  {
    title: 'Recordatorios',
    path: '/tasks/recordatorios',
    icon: icon('icon8'),
  },
  {
    title: 'Registros',
    path: '/tasks/issues',
    icon: icon('icon6'),
  },
  {
    title: 'Formularios',
    path: '/tasks/formularios',
    icon: icon('icon5')
  },
  {
    title: 'Tu Empresa',
    path: '/tasks/Empresa',
    icon: icon('icon3'),
  },
  {
    title: 'Salir',
    path: '/login',
    icon: icon('ic_lock'),
  }
];

export default navConfig;
