import React, { useState } from 'react';
import Button from '@mui/material/Button';

function ToggleButtons({ selectedButton, setSelectedButton }) {
  const handleButtonClick = (buttonValue) => {
    setSelectedButton(buttonValue);
  };

  const buttonStyles = {
    margin: '0 5px', // Agrega espacio horizontal entre los botones
  };

  return (
    <>
      <Button
        variant={selectedButton === 1 ? 'contained' : 'text'}
        sx={{ color: 'white' }}
        style={{
          ...buttonStyles,
          backgroundColor: selectedButton === 1 ? 'rgb(81, 160, 154)' : 'rgba(81, 160, 154, 0.45)',
        }}
        onClick={() => {
          handleButtonClick(1);
        }}
      >
        N
      </Button>
      <Button
        variant={selectedButton === 2 ? 'contained' : 'text'}
        sx={{ color: 'white' }}
        style={{
          ...buttonStyles,
          backgroundColor: selectedButton === 2 ? 'rgb(81, 160, 154)' : 'rgba(81, 160, 154, 0.45)',
        }}
        onClick={() => {
          handleButtonClick(2);
        }}
      >
        P
      </Button>
      <Button
        variant={selectedButton === 3 ? 'contained' : 'text'}
        sx={{ color: 'white' }}
        style={{
          ...buttonStyles,
          backgroundColor: selectedButton === 3 ? 'rgb(81, 160, 154)' : 'rgba(81, 160, 154, 0.45)',
        }}
        onClick={() => {
          handleButtonClick(3);
        }}
      >
        K
      </Button>
      <Button
        variant={selectedButton === 4 ? 'contained' : 'text'}
        sx={{ color: 'white' }}
        style={{
          ...buttonStyles,
          backgroundColor: selectedButton === 4 ? 'rgb(81, 160, 154)' : 'rgba(81, 160, 154, 0.45)',
        }}
        onClick={() => {
          handleButtonClick(4);
        }}
      >
        Ca
      </Button>
      <Button
        variant={selectedButton === 5 ? 'contained' : 'text'}
        sx={{ color: 'white' }}
        style={{
          ...buttonStyles,
          backgroundColor: selectedButton === 5 ? 'rgb(81, 160, 154)' : 'rgba(81, 160, 154, 0.45)',
        }}
        onClick={() => {
          handleButtonClick(5);
        }}
      >
        Mg
      </Button>
    </>
  );
}

export default ToggleButtons;
