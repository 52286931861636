import axios from 'axios';
import React from "react";
import { useEffect, useState, useRef } from "react";
import {
    Table,
    Card,
    Chip,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Box,
    MenuItem,
    Select,
    Typography,
    Button,
    Input,
    Link
} from "@mui/material";
import dotenv from 'dotenv';
import Swal from 'sweetalert2';

dotenv.config();
const url = process.env.REACT_APP_BASE_URL;
const urlWhatsapp = process.env.REACT_APP_WHATSAPP_URL;
console.log("urlWhatsapp", urlWhatsapp);


export default function CreateTaskLCP() {
    if (!localStorage.getItem('jwt')) {
        window.location.href = "/login";
    }
    const [task, setTask] = useState({});
    const [taskState, setTaskState] = useState(0);
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [taskType, setTaskType] = useState("");
    const [priority, setPriority] = useState(0);
    const [employee, setEmployee] = useState({});
    const jwt = localStorage.getItem('jwt');
    const data = jwt.split('.')[1];
    const user = JSON.parse(atob(data));
    const Empresa_id = user.Empresa_id;
    const User_id = user.User_id;
    const [selectedFile, setSelectedFile] = useState(null);
    const [employees, setEmployees] = useState([]);

    const handleTitle = (e) => {
        setTitle(e.target.value);
    }
    const handleDate = (e) => {
        setDate(e.target.value);
    }
    const handleTaskType = (e) => {
        setTaskType(e.target.value);
    }
    const handlePriority = (e) => {
        setPriority(e.target.value);
    }
    const handleTaskState = (e) => {
        setTaskState(e.target.value);
    }
    const handleEmployee = (e) => {
        setEmployee(e.target.value);
    }
    const restartData = () => {
        setTitle("");
        setDate("");
        setTaskType("");
        setPriority(0);
        setTaskState(0);
        document.getElementById("titleInput").value = null;
        document.getElementById("dateInput").value = null;
        document.getElementById("stateInput").value = null;
        document.getElementById("priorityInput").value = null;
        document.getElementById("typeInput").value = null;
    }
    const handleCreate = () => {
        if (!title.trim() || !date.trim() || !taskType.trim() || !employee) {
            Swal.fire({
                title: 'Error!',
                text: 'Por favor llene todos los campos',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }

        const date_aux = new Date();
        console.log("Hora creación tarea: ", date_aux);
        const time = `${date_aux.getHours()}:${date_aux.getMinutes()}:${date_aux.getSeconds()}`;
        
        const task = {
            "Empresa_id": Empresa_id, // Cambiar por el id de la empresa
            "User_id": employee,
            "titulo": title,
            "fecha": date,
            "estado": taskState,
            "prioridad": priority,
            "tipo_tarea": taskType,
            "tiempo": time,
        };

        console.log(task);

        fetch(`${url}/tareas/post`, {
            method: 'POST',
            headers: {
                'auth': jwt,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS'
            },
            body: JSON.stringify(task)
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        Swal.fire({
            title: 'Tarea creada!',
            text: 'La tarea ha sido creada con éxito',
            icon: 'success',
            confirmButtonText: 'Ok'
        });
        restartData();
    }

    useEffect(() => {
        fetch(`${url}/usuarios/empresa/${User_id}`)
            .then(response => response.json())
            .then(data => {
                console.log(data.usuarios);
                setEmployees(data.usuarios);
                setEmployee(data.usuarios[0].id);
            })
            .catch(error => {
                console.error('Error al obtener los datos:', error);
            });
    }, []);

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);

            axios.post(`${url}/tareas/uploadFile`, formData, {
                headers: {
                    'auth': jwt,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    console.log("Archivo subido con éxito");
                    setSelectedFile(null);
                    document.getElementById('uploadFile').value = null;
                    Swal.fire({
                        title: 'Archivo subido!',
                        text: 'El archivo ha sido subido con éxito',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });
                })
                .catch(error => {
                    console.error("Error al subir el archivo:", error.response.data.error);
                    Swal.fire({
                        title: 'Error!',
                        text: error.response.data.error,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                });
        }
    };

    return (
        <>
            <Card>
                <Box sx={{ p: 3 }}>
                    <Typography variant="h4">Crear Tarea</Typography>
                </Box>
                {employees ? <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Título</TableCell>
                                <TableCell>Fecha</TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell>Importancia</TableCell>
                                <TableCell>Descripción</TableCell>
                                <TableCell>Empleado</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableCell>
                                <Input id="titleInput" type="text" onChange={handleTitle} />
                            </TableCell>
                            <TableCell>
                                <TextField id="dateInput" type="date" onChange={handleDate} />
                            </TableCell>
                            <TableCell>
                                <Select
                                    id="stateInput"
                                    value={taskState}
                                    onChange={e => handleTaskState(e)}
                                    placeholder="Estado tarea"
                                >
                                    <MenuItem value={0}>Sin comenzar</MenuItem>
                                    {/* <MenuItem value={1}>Comenzada</MenuItem> */}
                                    <MenuItem value={2}>Finalizada</MenuItem>
                                </Select>
                            </TableCell>
                            <TableCell>
                                <Select
                                    id="priorityInput"
                                    value={priority}
                                    onChange={e => handlePriority(e)}
                                >
                                    <MenuItem value={3}>Urgente</MenuItem>
                                    <MenuItem value={2}>Medio</MenuItem>
                                    <MenuItem value={1}>Bajo</MenuItem>
                                    <MenuItem value={0}>Sin asignar</MenuItem>
                                </Select>
                            </TableCell>
                            <TableCell>
                                <Input id="typeInput" type="text" onChange={handleTaskType} />
                            </TableCell>
                            <TableCell>
                                <Select
                                    value={employee}
                                    onChange={e => handleEmployee(e)}
                                >
                                    {employees.map((employee) => {
                                        return <MenuItem key={employee.id} value={employee.id}>{employee.name}</MenuItem>
                                    })}
                                </Select>
                            </TableCell>
                        </TableBody>
                    </Table>
                </TableContainer> : <Typography variant="h6">No hay empleados</Typography>}
                <Box sx={{ p: 3 }}>
                    <Button variant="contained" onClick={handleCreate} sx={{ backgroundColor: '#91B38B' }}>Crear</Button>
                </Box>
                {/* <div>
                    <Typography variant="h4">Tareas a agregar</Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Titulo</TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Estado</TableCell>
                                    <TableCell>Tipo de tarea</TableCell>
                                    <TableCell>Empleado</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tasks.map((task) => {
                                    return <TableRow>
                                        <TableCell>{task.title}</TableCell>
                                        <TableCell>{task.date}</TableCell>
                                        <TableCell>{task.taskState}</TableCell>
                                        <TableCell>{task.taskType}</TableCell>
                                        <TableCell>{task.employee}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div> */}
            </Card>
            <Card sx={{ marginTop: 3 }}>
                <Box sx={{ paddingLeft: 3, paddingTop: 3 }}>
                    <Typography variant="h4">Crear multiples tareas</Typography>
                </Box>
                <Box sx={{ p: 3 }}>
                    <Link href="/tareas.xlsx" download="tareas.xlsx"><Button variant="contained" color="primary" component="span" sx={{ backgroundColor: '#91B38B' }}>Descargar archivo de ejemplo</Button></Link>
                </Box>
                <Box sx={{ paddingLeft: 3, paddingBottom: 3 }}>
                    <Typography variant="h6">El archivo con extensión .xlsx a subir debe tener los siguientes campos:</Typography>
                </Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Título (24 caracteres máximo)</TableCell>
                                <TableCell>Fecha (dd/mm/aaaa)</TableCell>
                                <TableCell>Estado (0 o 1)</TableCell>
                                <TableCell>Importancia 1,2,3 (Baja, Media, Urgente)</TableCell>
                                <TableCell>Descripción</TableCell>
                                <TableCell>Número (56912345678)</TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
                <TextField
                    sx={{ p: 3 }}
                    id="uploadFile"
                    type="file"
                    inputProps={{ accept: ".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }}
                    onChange={handleFileSelect}
                />
                <Box sx={{ p: 3 }}>
                    <Button variant="contained" color="primary" component="span" onClick={handleUpload} sx={{ backgroundColor: '#91B38B' }}>Subir archivo</Button>
                </Box>
            </Card>
        </>
    );
}