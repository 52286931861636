
import {Polygon} from 'react-leaflet'
import {useState} from 'react'

export const CustomPolygon = (props) => {
    const {index,unidad,onClick,selected, setMouseOver,setMouseOut} = props;
    const initialColor = selected ? "rgba(255, 255, 255,1)" : "rgb(255, 255, 255,0.5)";
    const [getColor,setColor] = useState(initialColor);
    return (
        <Polygon
            key={index}
            positions={unidad.coordenadas}
            id={index}
            cuartelNombre = {unidad}
            cuartelID = {unidad.id}
            pathOptions={{fillColor: getColor,color: getColor}}
            eventHandlers={{
                click: onClick,
                mouseover: (e) => {
                    setColor("rgb(255, 255, 255,0.75)")
                    setMouseOver(unidad);
                },
                mouseout:(e) => {
                    setColor(initialColor);
                    setMouseOut(unidad);
                }
            }}
        />
    )
}

export default CustomPolygon;