// components/Sidebar.js

import React from 'react';
import { Menu, X } from 'lucide-react';

export default function Sidebar({ sidebarOpen, toggleSidebar, setActiveTab, activeTab }) {
  const handleClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className={`bg-white text-black ${sidebarOpen ? 'w-52' : 'w-16'} transition-all duration-300 ease-in-out`}>
      <div className="p-4 flex justify-between items-center">
        <h2 className={`font-bold ${sidebarOpen ? '' : 'hidden'}`}>Wefleet</h2>
        <button onClick={toggleSidebar} className="text-black">
          {sidebarOpen ? <X /> : <Menu />}
        </button>
      </div>
      <nav>
        <button onClick={() => handleClick('tabla')} 
        className={activeTab === 'tabla' ? `w-full text-left p-4 hover:bg-gray-300 bg-gray-200` : `w-full text-left p-4 hover:bg-gray-200 bg-gray-100`}>
          {sidebarOpen ? 'Tabla' : 'T'}
        </button>
        <button onClick={() => handleClick('Estadisticas')} 
        className={activeTab === 'Estadisticas' ? `w-full text-left p-4 hover:bg-gray-300 bg-gray-200` : `w-full text-left p-4 hover:bg-gray-200 bg-gray-100`}>
          {sidebarOpen ? 'Historial' : 'H'}
        </button>
        <button onClick={() => handleClick('Fleeters')} 
        className={activeTab === 'Fleeters' ? `w-full text-left p-4 hover:bg-gray-300 bg-gray-200` : `w-full text-left p-4 hover:bg-gray-200 bg-gray-100`}>
          {sidebarOpen ? 'Fleeters' : 'F'}
        </button>
      </nav>
    </div>
  );
};
