import React, { useState, useEffect, useRef } from 'react'
import { Button } from '../../components/wefleet/Button'
import Filtros from '../../components/wefleet/Filtros'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/wefleet/Table'
import { Checkbox } from '../../components/wefleet/Checkbox'
import { Select } from '../../components/wefleet/Select'
import Botones from '../../components/wefleet/Botones'
import { Users, BarChart2 } from 'lucide-react'
import Sidebar from 'src/components/wefleet/Sidebar'
import Stats from 'src/components/wefleet/MessageHistory'
import { read, utils } from 'xlsx'
import wefleetUser from 'src/models/wefleetUser'
import Fleeters from 'src/components/wefleet/Fleeters'
import Swal from 'sweetalert2'
import dotenv from 'dotenv'
dotenv.config();

const URL_BACKEND = process.env.REACT_APP_BASE_URL;
const URL_WHATSAPP_WEFLEET = process.env.REACT_APP_URL_WHATSAPP_WEFLEET;

const send_whatsapp_message = async (number, name, operacion, message) => {
  const url_to_send = {
    "asistencia am": "send_turno_message",
    "asistencia pm": "send_turno_message",
    "paquetes recogidos": "send_paquetes_recogidos_message",
    "paquetes entregados": "send_paquetes_entregados_message",
    "rescate": "send_rescate_message"
  }
  try {
    const response = await fetch(`${URL_WHATSAPP_WEFLEET}/${url_to_send[message]}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "number": number, "nombre": name, "operacion": operacion, "turno": message })
    })
    const data = await response.json()
    
  }
  catch (error) {
    console.error('Error:', error)
  }
}



export default function WefleetTable() {
  if (!localStorage.getItem('jwt')) {
    window.location.href = "/loginWeFleet";
  }
  const [activeTab, setActiveTab] = useState('tabla')
  const [empleados, setEmpleados] = useState([])
  const [seleccionados, setSeleccionados] = useState({})
  const [tiposMensaje, setTiposMensaje] = useState({})
  const fileInputRef = useRef(null)
  const [filtros, setFiltros] = useState({
    ultimaRuta: '',
    vehiculo: '',
    turno: '',
    operacion: '',
    region: ''
  })

  const [tipoMensajeGlobal, setTipoMensajeGlobal] = useState('');
  const [tipoOperacionGlobal, setTipoOperacionGlobal] = useState('');
  const [messages, setMessages] = useState([])
  const [sidebarOpen, setSidebarOpen] = useState(false)

  function setupWebSocket(info) {
    const uri = 'wss://websocket-eqjlrpwjoa-uc.a.run.app';
    const connection = new WebSocket(uri);

    connection.addEventListener('open', () => {
    });

    connection.addEventListener('message', (event) => {
      const data = event.data;
      if ('wefleet' === data) {
        fetch(`${URL_BACKEND}/wefleet/message`, {
          method: 'GET',
          headers: {
            // 'auth': info.jwt,
            'mode': 'no-cors',
            'Content-Type': 'application/json',
          },


        }).then(response => response.json())
          .then(data => {
            console.log('Success Messages:', data);
            setMessages(data);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    });

    connection.addEventListener('close', () => {
      setupWebSocket();

    });
  };

  const toggleSidebar = () => {

    setSidebarOpen(!sidebarOpen)
  }

  useEffect(() => {
    setupWebSocket();
    // Simula la carga de datos desde Excel
    fetch(`${URL_BACKEND}/wefleet/users`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
    
        setEmpleados(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    fetch(`${URL_BACKEND}/wefleet/message`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        setMessages(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

  }, [])

  const handleCheckboxChange = (id) => {
    setSeleccionados(prev => ({ ...prev, [id]: !prev[id] }))
  }

  const handleAplicarOperacionGlobal = () => {
    setEmpleados((prevEmpleados) =>
      prevEmpleados.map((empleado) => {
        if (seleccionados[empleado.id]) {
          return { ...empleado, operacion: tipoOperacionGlobal };
        }
        return empleado;
      })
    );
  };


  const handleAplicarMensajeGlobal = () => {
    setEmpleados((prevEmpleados) =>
      prevEmpleados.map((empleado) => {
        if (seleccionados[empleado.id]) {
          return { ...empleado, tipoMensaje: tipoMensajeGlobal, operacion_a_participar: tipoOperacionGlobal };
        }
        return empleado;
      })
    );

    const nuevosTiposMensaje = {};
    empleadosFiltrados.forEach((emp) => {
      if (seleccionados[emp.id]) {
        nuevosTiposMensaje[emp.id] = tipoMensajeGlobal;
      } else {
        nuevosTiposMensaje[emp.id] = tiposMensaje[emp.id] || '';
      }
    });
    setTiposMensaje(nuevosTiposMensaje);
  };


  const handleSelectAll = () => {
    const todosSeleccionados = Object.keys(seleccionados).length === empleadosFiltrados.length &&
      Object.values(seleccionados).every(Boolean)

    if (todosSeleccionados) {
      setSeleccionados({})
    } else {
      const nuevosSeleccionados = {}
      empleadosFiltrados.forEach(emp => {
        nuevosSeleccionados[emp.id] = true
      })
      setSeleccionados(nuevosSeleccionados)
    }
  }

  const handleTipoMensajeChange = (id, value) => {
    setEmpleados(prev => prev.map(emp => {
      if (emp.id === id) {
        return { ...emp, tipoMensaje: value };
      }
      return emp;
    }));
    setTiposMensaje(prev => ({ ...prev, [id]: value }));
  };

  const handleEnviar = () => {
    const empleadosSeleccionados = empleadosFiltrados.filter(emp => seleccionados[emp.id])
    console.log("seleccionados", empleadosSeleccionados)
    if (empleadosSeleccionados.length === 0) {
      Swal.fire('Error', 'Debes seleccionar al menos un empleado para enviar el mensaje', 'error')
      return
    }
    if (empleadosSeleccionados.some(emp => emp.tipoMensaje === '' || emp.tipoMensaje === undefined || emp.tipoMensaje === null)) {
      Swal.fire('Error', 'Debes seleccionar un tipo de mensaje para todos los empleados seleccionados', 'error')
      return
    }
    if (empleadosSeleccionados.some(emp => emp.tipoMensaje === 'asistencia am' || emp.tipoMensaje === 'asistencia pm' || emp.tipoMensaje === 'rescate')) {
      if (empleadosSeleccionados.some(emp => emp.operacion_a_participar === "null" || emp.operacion_a_participar === '' || emp.operacion_a_participar === undefined)) {
        Swal.fire('Error', 'Debes seleccionar una operación para enviar el mensaje de asistencia', 'error')
        return
      }
    }
      Swal.fire({
        title: '¿Estás seguro que deseas enviar el mensaje?',
        showDenyButton: true,
        confirmButtonText: `Enviar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          empleadosSeleccionados.forEach(emp => {
            send_whatsapp_message(emp.telefono, emp.nombre, emp.operacion_a_participar, emp.tipoMensaje)
            setEmpleados(prev => prev.map(e => {
              if (e.id === emp.id) {
                return { ...e, response: 'Enviado', ultimoMensaje: { tiempo: new Date().toLocaleString(), tipo: tiposMensaje[emp.id] } }
              }
              return e
            }
            ))
          })
          Swal.fire('Mensaje enviado!', '', 'success')
        }
      })

    }




    const handleDescargar = () => {
      
    }

    const handleFiltroChange = (campo, valor) => {
      setFiltros(prev => ({ ...prev, [campo]: valor }))
    }

    const empleadosFiltrados = empleados.filter(emp =>
      (filtros.ultimaRuta === '' || emp.ultimaRuta === filtros.ultimaRuta) &&
      (filtros.vehiculo === '' || emp.vehiculo === filtros.vehiculo) &&
      (filtros.turno === '' || emp.turno === filtros.turno) &&
      (filtros.operacion === '' || emp.operacion === filtros.operacion) &&
      (filtros.region === '' || emp.region === filtros.region)
    )

    const getUniqueValues = (field) => {
      return ['', ...new Set(empleados.map(emp => emp[field]))]
    }

    function fixDate(dateString) {
      const date = new Date(dateString);
    
      // Leer el offset de localStorage y convertirlo a número
      const offsetHours = Number(localStorage.getItem('offset')) * -1;
    
      // Convertir el offset de horas a milisegundos y aplicarlo
      const offsetMilliseconds = offsetHours * 60 * 60 * 1000;
      date.setTime(date.getTime() + offsetMilliseconds);
    
      const pad = (num) => (num < 10 ? '0' + num : num);
    
      const day = pad(date.getDate());
      const month = pad(date.getMonth() + 1); // Los meses en JavaScript son 0-11
      const year = date.getFullYear();
      const hours = pad(date.getHours());
      const minutes = pad(date.getMinutes());
      const seconds = pad(date.getSeconds());
    
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }

    return (
      <div className="flex h-screen bg-gray-100">
        <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} activeTab={activeTab} setActiveTab={setActiveTab} />
        <div className="flex-1 flex flex-col overflow-hidden">
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
            <div className="container mx-auto px-6 py-8">
              {activeTab === 'tabla' ? (
                <div className="space-y-4">
                  <div className="relative z-20">
                    <Filtros
                      filtros={filtros}
                      handleFiltroChange={handleFiltroChange}
                      getUniqueValues={getUniqueValues}
                    />
                  </div>
                  {/* Nuevo Select y Botón para aplicar a seleccionados */}
                  <div className="flex items-center justify-between mb-4">
                    <Select
                      className='w-64'
                      options={[
                        { value: 'asistencia am', label: 'Asistencia Am' },
                        { value: 'asistencia pm', label: 'Asistencia Pm' },
                        { value: 'paquetes recogidos', label: 'Cantidad paquetes recogidos' },
                        { value: 'paquetes entregados', label: 'Cantidad paquetes entregados' },
                        { value: 'rescate', label: 'Rescate' },
                      ]}
                      value={tipoMensajeGlobal}
                      onChange={setTipoMensajeGlobal}
                      placeholder="Seleccionar Mensaje a aplicar"
                    />
                    <Select
                      className='w-64'
                      options={[
                        { value: 'chilexpress', label: 'Chilexpress' },
                        { value: 'bluexpress rm', label: 'Bluexpress RM' },
                        { value: 'bluexpress v', label: 'Bluexpress V' },
                        { value: 'bluexpress los angeles', label: 'Bluexpress Los Angeles' },
                        { value: 'bluexpress punta arenas', label: 'Bluexpress Punta Arenas' },
                        { value: 'starken', label: 'Starken' },
                        { value: 'spread', label: 'Spread' },
                        { value: 'despachalo', label: 'Despachalo' },
                        { value: 'recibelo', label: 'Recíbelo' },
                        { value: 'fazt', label: 'Fazt' },
                        { value: 'valdishopper', label: 'Valdishopper' },
                        { value: 'el carnicero', label: 'El Carnicero' },
                        { value: 'ditzler', label: 'Ditzler' },
                        { value: 'global frozen', label: 'Global Frozen' },
                        { value: 'bata', label: 'Bata' },
                        { value: 'grupo h', label: 'Grupo H' }
                      ]}
                      value={tipoOperacionGlobal}
                      onChange={setTipoOperacionGlobal}
                      placeholder="Seleccionar operación a aplicar"
                    />
                    <Button className="bg-black hover:bg-gray-800" onClick={handleAplicarMensajeGlobal}>Aplicar a Seleccionados</Button>
                  </div>

                  <div className="overflow-x-auto relative z-10 bg-white shadow-md rounded-lg">
                    <div className="max-h-[600px] overflow-y-auto">
                      <Table>
                        <TableHeader className="sticky top-0 bg-white z-10">
                          <TableRow>
                            <TableHead className="bg-white ">
                              <Checkbox
                                checked={
                                  Object.keys(seleccionados).length === empleadosFiltrados.length &&
                                  Object.values(seleccionados).every(Boolean)
                                }
                                onChange={handleSelectAll}
                                label="Seleccionar"
                              />
                            </TableHead>
                            <TableHead className="bg-white">Nombre</TableHead>
                            <TableHead className="bg-white">Teléfono</TableHead>
                            <TableHead className="bg-white">Email</TableHead>
                            <TableHead className="bg-white">Operacion</TableHead>
                            {/* <TableHead className="bg-white">Vehículo</TableHead> */}
                            <TableHead className="bg-white">Patente</TableHead>
                            <TableHead className="bg-white">Turno</TableHead>
                            <TableHead className="bg-white">Operación a participar</TableHead>
                            {/* <TableHead className="bg-white">Región</TableHead> */}
                            <TableHead className="bg-white">Último Mensaje</TableHead>
                            <TableHead className="bg-white">Tipo de Mensaje</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {empleadosFiltrados.map((empleado) => (
                            <TableRow key={empleado.id}>
                              <TableCell>
                                <Checkbox
                                  checked={seleccionados[empleado.id] || false}
                                  onChange={() => handleCheckboxChange(empleado.id)}
                                />
                              </TableCell>
                              <TableCell>{empleado.nombre}</TableCell>
                              <TableCell>{empleado.telefono}</TableCell>
                              <TableCell>{empleado.email}</TableCell>
                              <TableCell>{empleado.operacion_usuario}</TableCell>
                              {/* <TableCell>{empleado.vehiculo}</TableCell> */}
                              <TableCell>{empleado.patente}</TableCell>
                              <TableCell>{empleado.turno}</TableCell>
                              <TableCell>{empleado.operacion_a_participar}</TableCell>
                              {/* <TableCell>{empleado.region}</TableCell> */}
                              <TableCell>
                                <span
                                  className={`inline-block w-2 h-2 rounded-full mr-1 ${empleado.ultimo_mensaje === 'Si' || empleado.ultimo_mensaje === 'Am' || empleado.ultimo_mensaje === 'Pm' ? 'bg-green-500' :
                                      empleado.ultimo_mensaje === 'Enviado' ? 'bg-blue-500' :
                                        empleado.ultimo_mensaje === 'No' ? 'bg-red-500' :
                                          empleado.ultimo_mensaje === 'No enviado' ? 'bg-gray-500' :
                                            'bg-yellow-500' // Default color if none of the conditions match
                                    }`}
                                ></span>
                                {empleado.ultimo_mensaje ? `${fixDate(empleado.fecha)}` : 'Subido recientemente'}
                                {/* {empleado.ultimo_mensaje ? `${fixDate(empleado.fecha)} - ${empleado.ultimo_mensaje}` : 'Subido recientemente'} */}
                              </TableCell>
                              <TableCell>
                                <Select
                                  options={[
                                    { value: 'asistencia am', label: 'Asistencia Am' },
                                    { value: 'asistencia pm', label: 'Asistencia Pm' },
                                    { value: 'paquetes recogidos', label: 'Cantidad paquetes recogidos' },
                                    { value: 'paquetes entregados', label: 'Cantidad paquetes entregados' },
                                    { value: 'rescate', label: 'Rescate' },
                                  ]}
                                  value={tiposMensaje[empleado.id] || (empleado.ultimoMensaje ? empleado.ultimoMensaje.tipo : 'turno')}
                                  onChange={(value) => handleTipoMensajeChange(empleado.id, value)}
                                  placeholder="Tipo de Mensaje"
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <Button className="bg-black hover:bg-gray-800" onClick={handleEnviar}>Enviar</Button>
                  </div>
                </div>
              ) : (activeTab === 'Estadisticas' ? (
                <Stats messages={messages} />
              ) : (activeTab === 'Fleeters' ? (
                <Fleeters empleados={empleados} setEmpleados={setEmpleados} />
              ) : (null)
              ))}

            </div>
          </main>
        </div>
      </div>
    )
  }