/* eslint-disable react-hooks/exhaustive-deps */
import dotenv from 'dotenv';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Card, Chip, IconButton, Input, MenuItem, Select, Tab, Table, Tabs, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';

dotenv.config();
const url = process.env.REACT_APP_BASE_URL;

export default function Reminder() {
    if (!localStorage.getItem('jwt')) {
        window.location.href = "/login";
    }

    const [employees, setEmployees] = useState([]);
    const [Empresa_id, setEmpresa_id] = useState(0);
    const [jwt, setJwt] = useState("");
    const [reminderDescription, setReminderDescription] = useState("");
    const [reminderEmployee, setReminderEmployee] = useState({});
    const [reminderFrecuency, setReminderFrecuency] = useState(0);
    const [reminderPriority, setReminderPriority] = useState(0);
    const [reminderTime, setReminderTime] = useState("");
    const [reminderTitle, setReminderTitle] = useState("");
    const [reminders, setReminders] = useState([]);
    const [tabValue, setTabValue] = useState(1);

    const fixFrecuency = (frecuency) => {
        switch (parseInt(frecuency)) {
            case 0:
                return "Todos los días";
            case 1:
                return "Todos los lunes";
            case 2:
                return "Todos los martes";
            case 3:
                return "Todos los miércoles";
            case 4:
                return "Todos los jueves";
            case 5:
                return "Todos los viernes";
            case 6:
                return "Todos los sábados";
            case 7:
                return "Todos los domingos";
            case 8:
                return "Todos los días de semana (lunes a viernes)";
            default:
                return;
        }
    }

    const fixPriority = (priority) => {
        if (parseInt(priority) === 0 || priority === "") {
            return "Sin asignar";
        } else if (parseInt(priority) === 1) {
            return "Bajo";
        } else if (parseInt(priority) === 2) {
            return "Medio"
        } else {
            return "Urgente";
        }
    }

    const handleCreate = () => {
        if (!reminderDescription.trim() || !reminderTime.trim() || !reminderTitle.trim() || !reminderEmployee) {
            Swal.fire({
                title: 'Error!',
                text: 'Por favor llene todos los campos',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }

        const reminder = {
            "Empresa_id": Empresa_id,
            "User_id": reminderEmployee,
            "descripcion": reminderDescription,
            "frecuencia": reminderFrecuency,
            "hora": reminderTime,
            "prioridad": reminderPriority,
            "titulo": reminderTitle,
            "offset": localStorage.getItem('offset')
        }

        fetch(`${url}/recordatorios/post`, {
            method: 'POST',
            headers: {
                'auth': jwt,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS'
            },
            body: JSON.stringify(reminder)
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
        })
        .catch(error => {
            console.error('Error:', error);
        });

        Swal.fire({
            title: 'Recordatorio creado!',
            text: 'El recordatorio ha sido creado con éxito',
            icon: 'success',
            confirmButtonText: 'Ok'
        });

        restartReminder();

        fetch(`${url}/recordatorios/empresa/${Empresa_id}`, {
            method: 'GET',
            headers: {
                'auth': jwt,
                'mode': 'no-cors',
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => setReminders(data));
    };

    const handleDelete = (id) => {
        fetch(`${url}/recordatorios/delete/${id}`, {
            method: 'DELETE',
            headers: {
            'Content-Type': 'application/json',
            'auth': jwt
            },
        })
        .then(response => response.json())
        .then(data => {
            setReminders(reminders.filter(reminder => reminder.id !== id));
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const handleReminderDescription = (e) => {
        setReminderDescription(e.target.value);
    };

    const handleReminderEmployee = (e) => {
        setReminderEmployee(e.target.value);
    };

    const handleReminderFrecuency = (e) => {
        setReminderFrecuency(e.target.value);
    };

    const handleReminderPriority = (e) => {
        setReminderPriority(e.target.value);
    };

    const handleReminderTime = (e) => {
        setReminderTime(e.target.value);
    };

    const handleReminderTitle = (e) => {
        setReminderTitle(e.target.value);
    };

    const handleSave = (id, prioridad) => {
        fetch(`${url}/recordatorios/put/prioridad/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'auth': jwt
            },
            body: JSON.stringify({prioridad})
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
        });
        setReminders(reminders);
    };
    
    const handleImportanceChange = (e, id) => {
        const newPriority = e.target.value;
        const updatedReminders = reminders.map(reminder => {
            if (reminder.id === id) {
                reminder.prioridad = newPriority;
            }
            return reminder;
        });
        handleSave(id, newPriority);
        setReminders(updatedReminders);
    };

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
    };

    function fixHour(hour, offset) {
        console.log(hour, offset);
        const [hours, minutes] = hour.split(":").map(Number);
        const adjustedHours = (hours - offset + 24) % 24; // Sumar 24 y luego usar módulo 24 para manejar el ciclo de horas
        const formattedHours = adjustedHours.toString().padStart(2, "0"); // Asegura que siempre haya dos dígitos
        return `${formattedHours}:${minutes.toString().padStart(2, "0")}`; // Asegura que los minutos tengan dos dígitos
    }
    

    const renderTable = (reminders) => {
        return reminders.map(reminder => {
            return (
                <TableRow key={reminder.id}>
                    <TableCell>{reminder.titulo}</TableCell>
                    <TableCell>{fixHour(reminder.hora, reminder.offset)}</TableCell>
                    <TableCell>{fixFrecuency(reminder.frecuencia)}</TableCell>
                    <TableCell>
                        <Select value={reminder.prioridad} label={fixPriority(reminder.prioridad)} onChange={e => handleImportanceChange(e, reminder.id)}
                        sx={
                            {
                                '& .MuiSelect-select': {
                                    display: 'flex',
                                    padding: '3px',
                                    alignItems: 'center'
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none'
                                },
                            }
                        }>
                            <MenuItem value={3}>
                                <Chip label="Urgente" sx={{ backgroundColor: '#DD7C36', color: 'white', height: '24px' }} />
                            </MenuItem>
                            <MenuItem value={2}>
                                <Chip label="Medio" sx={{ backgroundColor: '#FDFF85', color: 'black', paddingRight: '11px', height: '24px' }} />
                            </MenuItem>
                            <MenuItem value={1}>
                                <Chip label="Bajo" sx={{ backgroundColor: '#B8C975', color: 'white', paddingRight: '20px', height: '24px' }} />
                            </MenuItem>
                            <MenuItem value={0}>
                                <Chip label="Sin asignar" sx={{ backgroundColor: '#F5F5F5', color: 'black', paddingRight: '20px', height: '24px' }} />
                            </MenuItem>
                        </Select>
                    </TableCell>
                    <TableCell>{reminder.descripcion}</TableCell>
                    <TableCell>{reminder.empleado}</TableCell>
                    <TableCell>
                        <IconButton onClick={() => handleDelete(reminder.id)}>
                            <RemoveIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            );
        });
    };

    const restartReminder = () => {
        setReminderDescription("");
        setReminderFrecuency(0);
        setReminderPriority(0);
        setReminderTime("");
        setReminderTitle("");
        document.getElementById("descriptionInput").value = null;
        document.getElementById("frecuencyInput").value = null;
        document.getElementById("priorityInput").value = null;
        document.getElementById("timeInput").value = null;
        document.getElementById("titleInput").value = null;
    };

    function setupJwt() {
        const jwt = localStorage.getItem('jwt');
        setJwt(jwt);
        const decoded = Buffer.from(jwt.split('.')[1], 'base64').toString('ascii');
        const data = JSON.parse(decoded);
        const response = {
            "jwt": jwt,
            "Empresa_id": data.Empresa_id,
            "User_id": data.User_id
        }
        setEmpresa_id(data.Empresa_id);
        return response;
    };

    const setupWebSocket = (info) => {
        const uri = 'wss://websocket-eqjlrpwjoa-uc.a.run.app';
        const connection = new WebSocket(uri);
    
        connection.addEventListener('open', () => {
            console.log('Connected to the WebSocket server');
        });
    
        connection.addEventListener('message', (event) => {
            const data = event.data;
            console.log('Received the defined message:', data);
            const refreshed_id = data.split(':')[1].trim();

            if (parseInt(info.Empresa_id) === parseInt(refreshed_id)) {
                fetch(`${url}/recordatorios/empresa/${info.Empresa_id}`, {
                    method: 'GET',
                    headers: {
                        'auth': info.jwt,
                        'mode': 'no-cors',
                        'Content-Type': 'application/json',
                    }
                })
                .then(response => response.json())
                .then(data => setReminders(data));

                fetch(`${url}/usuarios/empresa/${info.User_id}`)
                .then(response => response.json())
                .then(data => {
                    setEmployees(data.usuarios);
                    setReminderEmployee(data.usuarios[0].id);
                });
            }
        });
        connection.addEventListener('close', () => setupWebSocket());
    };

    useEffect(() => {
        const info = setupJwt();
        setupWebSocket(info);
        fetch(`${url}/recordatorios/empresa/${info.Empresa_id}`, {
            method: 'GET',
            headers: {
                'auth': info.jwt,
                'mode': 'no-cors',
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => setReminders(data))
        .catch(error => {
            console.error('Error al obtener los datos:', error);
        });

        fetch(`${url}/usuarios/empresa/${info.User_id}`)
        .then(response => response.json())
        .then(data => {
            setEmployees(data.usuarios);
            setReminderEmployee(data.usuarios[0].id);
        })
        .catch(error => {
            console.error('Error al obtener los datos:', error);
        });
    }, []);

    return (
        <>
            <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Recordatorios" value={1} />
                <Tab label="Crear recordatorios" value={2} />
            </Tabs>
            {tabValue === 1 &&
            <Card sx={{ py: 1.5, height: "80vh", display: 'flex', flexDirection: 'column', color: '#3C4447' }}>
                <Box sx={{ px: 3, py: 1.5 }}>
                    <Typography variant="h4">Recordatorios</Typography>
                </Box>
                {
                    reminders.length > 0 ?
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Título</TableCell>
                                    <TableCell>Hora</TableCell>
                                    <TableCell>Frecuencia</TableCell>
                                    <TableCell>Importancia</TableCell>
                                    <TableCell>Descripción</TableCell>
                                    <TableCell>Empleado</TableCell>
                                    <TableCell>Eliminar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reminders.map((reminder) => (renderTable([reminder])))}
                            </TableBody>
                        </Table>
                    </TableContainer> :
                    <Box sx={{ px: 3, py: 1.5 }}>
                        <Typography variant="h6">No hay recordatorios</Typography>
                    </Box>
                }
            </Card>
            }
            {tabValue === 2 &&
            <Card sx={{ py: 1.5, height: "45vh", display: 'flex', flexDirection: 'column', color: '#3C4447' }}>
                <Box sx={{ px: 3, py: 1.5 }}>
                    <Typography variant="h4">Crear Recordatorio</Typography>
                </Box>
                {
                    employees.length !== 0 ?
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Título</TableCell>
                                    <TableCell>Hora</TableCell>
                                    <TableCell>Frecuencia</TableCell>
                                    <TableCell>Importancia</TableCell>
                                    <TableCell>Descripción</TableCell>
                                    <TableCell>Empleado</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Input id="titleInput" type="text" onChange={handleReminderTitle} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField id="timeInput" type="time" onChange={handleReminderTime} />
                                    </TableCell>
                                    <TableCell>
                                        <Select id="frecuencyInput" value={reminderFrecuency} onChange={handleReminderFrecuency}>
                                            <MenuItem value={0}>Todos los días</MenuItem>
                                            <MenuItem value={1}>Todos los lunes</MenuItem>
                                            <MenuItem value={2}>Todos los martes</MenuItem>
                                            <MenuItem value={3}>Todos los miércoles</MenuItem>
                                            <MenuItem value={4}>Todos los jueves</MenuItem>
                                            <MenuItem value={5}>Todos los viernes</MenuItem>
                                            <MenuItem value={6}>Todos los sábados</MenuItem>
                                            <MenuItem value={7}>Todos los domingos</MenuItem>
                                            <MenuItem value={8}>Todos los días de semana (lunes a viernes)</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell>
                                        <Select id="priorityInput" value={reminderPriority} onChange={handleReminderPriority}>
                                            <MenuItem value={3}>Urgente</MenuItem>
                                            <MenuItem value={2}>Medio</MenuItem>
                                            <MenuItem value={1}>Bajo</MenuItem>
                                            <MenuItem value={0}>Sin asignar</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell>
                                        <Input id="descriptionInput" type="text" onChange={handleReminderDescription} />
                                    </TableCell>
                                    <TableCell>
                                        <Select value={reminderEmployee} onChange={handleReminderEmployee}>
                                            {employees.map((employee) => {
                                                return <MenuItem key={employee.id} value={employee.id}>{employee.name}</MenuItem>
                                            })}
                                        </Select>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer> :
                    <Box>
                        <Typography variant="h6">No hay empleados</Typography>
                    </Box>
                }
                <Box sx={{ p: 3 }}>
                    <Button variant="contained" onClick={handleCreate} sx={{ backgroundColor: '#91B38B' }}>Crear</Button>
                </Box>
            </Card>
            }
        </>
    );
}