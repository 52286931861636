import React from 'react';
import TextField from '@mui/material/TextField';


export const NumberInputComponent = (props) => {
  const { value, onChange, label } = props;

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const onlyNumbers = inputValue.replace(/[^0-9]/g, '');
    const onlyNumbersInt = parseInt(onlyNumbers);

    onChange(isNaN(onlyNumbersInt) ? 0 : onlyNumbersInt);
  };

  return (
    <TextField
      value={value}
      onChange={handleInputChange}
      label={label}
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
    />
  );
}

export default NumberInputComponent;
