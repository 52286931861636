// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: "Objetivo de producción",
    path: '/dashboard/CalculationPage',
  },
  {
    title: "Compra de productos",
    path: '/dashboard/Fundos',
  },
  {
    title: "Calendario de fertilización",
    path: '/dashboard/Aplicación',

  },
  /* {
    title: "agregar cuartel",
    path: '/dashboard/crearCuartel',
  }, */
  {
    title: 'Sensores',
    path: '/dashboard/ChartPage',
    icon: icon('ic_lock'),
  },
  {
    title: 'Salir',
    path: '/login',
    icon: icon('icon3'),
  }
];

export default navConfig;
