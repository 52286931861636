import React, { useEffect, useState } from 'react';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Input, Select, MenuItem, Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import dotenv from 'dotenv';
import Swal from 'sweetalert2';
import { set, sortBy } from 'lodash';




dotenv.config();
const url = process.env.REACT_APP_BASE_URL;
const urlWhatsapp = process.env.REACT_APP_WHATSAPP_URL;

export default function EmpresaLCP() {
    if (!localStorage.getItem('jwt')) {
        window.location.href = "/login";
    }
    const [employees, setEmployees] = useState([]);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [employeeType, setEmployeeType] = useState("Operario");
    const jwt = localStorage.getItem('jwt');
    const decoded = jwt.split('.')[1];
    const user = JSON.parse(atob(decoded));
    const Empresa_id = user.Empresa_id;
    
    const postEmployee = () => {
        if (name.length === 0 || email.length === 0 || phone.length === 0) {
            Swal.fire({
                title: 'Error',
                text: 'Por favor, complete todos los campos',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }
        if (phone.includes('+')){
            setPhone(phone.replace('+', ''));
        }
        setEmployees([...employees, { name, email, phone, employeeType }]);
        fetch(`${url}/usuarios/post`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'auth': jwt
            },
            body: JSON.stringify({
                    "name": name,
                    "email": email,
                    "phone": phone,
                    "employeeType": employeeType,
                    "empresa_id": Empresa_id
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.message) {
                Swal.fire({
                    title: 'Error ',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            }
            else {
                Swal.fire({
                    title: 'Exito!',
                    text: 'Empleado creado exitosamente',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
            }
        }
        )
        .catch(error => {
            console.error('Error:', error);
        });

        fetch(`${urlWhatsapp}/send-welcome-message`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Allow-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
                "nombre": name,
                "number": phone,
                "empresa": Empresa_id
            })

        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
        })
        .catch(error => {
            console.error('Error:', error);
        });
        setName("");
        setEmail("");
        setPhone("");
        setEmployeeType("");
    }

    
    useEffect(() => {
        fetch(`${url}/usuarios/empresa/${user.User_id}`)
        .then(response => response.json())
        .then(data => {
            setEmployees(sortBy(data.usuarios, ['employeeType']));
        })
        .catch(error => {
            console.error('Error al obtener los datos:', error);
        });
    }, []);

    const handleEdit = (e) => {
        console.log(e);
        Swal.fire({
            'title': 'Aun no implementado',
            'text': 'Esta funcionalidad aun no ha sido implementada',
            'icon': 'info',
            'confirmButtonText': 'Ok'
        });
    }
    const handleDelete = (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, bórralo!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${url}/usuarios/delete/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'auth': jwt
                    }
                })
                .then(response => response.json())
                .then(data => {
                    setEmployees(data);
                    Swal.fire(
                        'Eliminado!',
                        'El usuario ha sido eliminado.',
                        'success'
                    );
                })
                .catch(error => {
                    console.error('Error:', error);
                    Swal.fire(
                        'Error!',
                        'Hubo un problema al eliminar el usuario.',
                        'error'
                    );
                });
            }
        });
    }

    return (
        <>
            <h1>Equipo</h1>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Telefono</TableCell>
                            <TableCell>Tipo de empleado</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {employees.map((employee, index) => (
                            
                            <TableRow key={index}>
                                <TableCell>{employee.name}</TableCell>
                                <TableCell>{(employee.email).length > 0 ? employee.email : 'Email faltante'}</TableCell>
                                <TableCell>{employee.phone}</TableCell>
                                <TableCell>{employee.employeeType}</TableCell>
                                {employee.employeeType === "Administrador" ? null :
                                <TableCell>
                                <IconButton
                                    onClick={() => handleEdit(employee.id)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDelete(employee.id)}>
                                    <RemoveIcon />
                                </IconButton>
                            </TableCell>}
                                
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell><Input placeholder="Nombre" value={name} onChange={e => setName(e.target.value)} /></TableCell>
                            <TableCell><Input placeholder="Mail" value={email} onChange={e => setEmail(e.target.value)} /></TableCell>
                            <TableCell><Input placeholder="Telefono" value={phone} onChange={e => setPhone(e.target.value)} /></TableCell>
                            <TableCell><Select
                                value={employeeType}
                                onChange={e => setEmployeeType(e.target.value)}
                                placeholder="Tipo de empleado"
                            >
                                <MenuItem value={"Supervisor"}>Supervisor</MenuItem>
                                <MenuItem value={"Operario"}>Operario</MenuItem>
                            </Select>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{ p: 3 }}>
                <Button sx={ {backgroundColor: '#91B38B'} } variant="contained" onClick={postEmployee}>Crear</Button>
            </Box>
        </>
    )
}