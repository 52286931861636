// components/MainView.js

import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import Process from './Process'
import Register from './Register'
import { Plus } from 'lucide-react'

const MainView = ({
        boards,
        selectedBoard,
        registers,
        addNewProcess,
        addNewRegister,
        newRegisterTask,
        setNewRegisterTask,
        toggleStatusDropdown,
        togglePriorityDropdown,
        updateTaskStatus,
        updateTaskPriority,
        openStatusDropdown,
        openPriorityDropdown,
        toggleEditMode,
        updateProcessTitle,
        finishEditingProcessTitle,
        handleKeyDown,
        deleteProcess,
        addNewTask,
        updateNewTaskContent,
}) => {
  const currentBoard = boards.find(board => board.id === selectedBoard)

  return (
    <div className="flex space-x-4">
      <div className="w-3/4">
        <Droppable droppableId="processes" type="process">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
              {currentBoard?.processes.map((process, index) => (
                <Process
                  key={process.id}
                  process={process}
                  index={index}
                  toggleStatusDropdown={toggleStatusDropdown}
                  togglePriorityDropdown={togglePriorityDropdown}
                  updateTaskStatus={updateTaskStatus}
                  updateTaskPriority={updateTaskPriority}
                  openStatusDropdown={openStatusDropdown}
                  openPriorityDropdown={openPriorityDropdown}
                  toggleEditMode={toggleEditMode}
                  updateProcessTitle={updateProcessTitle}
                  finishEditingProcessTitle={finishEditingProcessTitle}
                  handleKeyDown={handleKeyDown}
                  deleteProcess={deleteProcess}
                  addNewTask={addNewTask}
                  updateNewTaskContent={updateNewTaskContent}
                  addNewProcess={addNewProcess}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <div className="mt-4">
          <button
            onClick={addNewProcess}
            className="bg-green-500 text-white p-2 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            aria-label="Add new process"
          >
            <Plus className="w-6 h-6" />
          </button>
        </div>
      </div>
      <div className="w-1/4">
        <div className="bg-white rounded-lg shadow-md p-4">
          <h2 className="text-lg font-semibold mb-4">Registers</h2>
          <Droppable droppableId="registers" type="task">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                {registers.map((register, index) => (
                  <Register
                    key={register.id}
                    register={register}
                    index={index}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="mt-4 flex space-x-2">
            <input
              type="text"
              placeholder="New register task"
              value={newRegisterTask}
              onChange={(e) => setNewRegisterTask(e.target.value)}
              className="flex-grow border rounded px-2 py-1"
            />
            <button
              onClick={addNewRegister}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            >
              Add Register
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainView