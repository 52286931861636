import React from 'react';
import './calendar.css';

import { TaskAdd } from './taskadd';
import { Task } from './task';
import products from 'src/_mock/products';

export const Calendar = ({
  months,
  scheduledProducts,
  orderProducts,
  handleRemove,
  handleAddMore,
  checked
}) => {
  return (
    <div className='calendar' >
      {
        months.map(month => (
          <div className='month' >
            <div className='month-name' >{month}</div>
            {
              scheduledProducts.filter((scheduledProduct) => {
                return scheduledProduct.month === month;
              }).map((scheduledProduct) => {
                return (
                  <Task
                    month={month}
                    scheduledProduct={scheduledProduct}
                    handleRemove={handleRemove}
                    completedPercentage={scheduledProduct.fulfilled}
                    checked={checked}
                  ></Task>
                )
              })
            }

            <TaskAdd
              month={month}
              orderProducts={orderProducts}
              handleAddMore={handleAddMore}
            ></TaskAdd>

          </div>
        ))
      }
    </div>
  )
}