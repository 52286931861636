// @mui
import {
    Grid,
    Card,
    Button,
    TableHead,
    TableRow,
    TableCell,
    Box,
    Typography,
  } from "@mui/material";



export default function EnviosPage() {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card>
                    <Box p={2}>
                        <Typography variant="h4" >
                            Envios
                        </Typography>
                    </Box>
                    <Box p={2} display="flex" justifyContent="center" alignItems="center">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" >
                                            ID
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" >
                                            Nombre
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" >
                                            Apellido
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" >
                                            Email
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" >
                                            Direccion
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" >
                                            Ciudad
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" >
                                            Estado
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" >
                                            Codigo Postal
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" >
                                            Pais
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" >
                                            Fecha de Creacion
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" >
                                            Acciones
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                    </Box>

                </Card>
            </Grid>
        </Grid>
    );
}