'use client'

import React, { useState } from 'react'
import { PencilIcon, TrashIcon, PlusIcon } from 'lucide-react'

const initialWorkers = [
  { id: '1', nombre: "Juan Pérez", telefono: "123-456-7890", rol: "Desarrollador" },
  { id: '2', nombre: "María García", telefono: "098-765-4321", rol: "Diseñadora" },
  { id: '3', nombre: "Carlos López", telefono: "555-555-5555", rol: "Gerente de Proyecto" },
]

const generateId = () => Math.random().toString(36).substr(2, 9)

export default function EmpresaView() {
  const [workers, setWorkers] = useState(initialWorkers)
  const [editingWorker, setEditingWorker] = useState(null)
  const [newWorker, setNewWorker] = useState({ nombre: '', telefono: '', rol: '' })
  const [isAddingWorker, setIsAddingWorker] = useState(false)

  const handleEdit = (worker) => {
    setEditingWorker(worker)
    setIsAddingWorker(false)
  }

  const handleDelete = (id) => {
    setWorkers(workers.filter(worker => worker.id !== id))
  }

  const handleSave = (event) => {
    event.preventDefault()
    if (editingWorker) {
      setWorkers(workers.map(worker => 
        worker.id === editingWorker.id ? editingWorker : worker
      ))
      setEditingWorker(null)
    }
  }

  const handleInputChange = (event) => {
    if (editingWorker) {
      setEditingWorker({
        ...editingWorker,
        [event.target.name]: event.target.value
      })
    }
  }

  const handleNewWorkerInputChange = (event) => {
    setNewWorker({
      ...newWorker,
      [event.target.name]: event.target.value
    })
  }

  const handleAddWorker = (event) => {
    event.preventDefault()
    const id = generateId()
    setWorkers([...workers, { id, ...newWorker }])
    setNewWorker({ nombre: '', telefono: '', rol: '' })
    setIsAddingWorker(false)
  }

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4">Trabajadores de la Empresa</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Teléfono</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rol</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {workers.map((worker) => (
              <tr key={worker.id}>
                <td className="px-6 py-4 whitespace-nowrap">{worker.nombre}</td>
                <td className="px-6 py-4 whitespace-nowrap">{worker.telefono}</td>
                <td className="px-6 py-4 whitespace-nowrap">{worker.rol}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button 
                    onClick={() => handleEdit(worker)}
                    className="text-black hover:text-gray-700 mr-2"
                    aria-label={`Editar ${worker.nombre}`}
                  >
                    <PencilIcon className="h-5 w-5" />
                  </button>
                  <button 
                    onClick={() => handleDelete(worker.id)}
                    className="text-black hover:text-gray-700"
                    aria-label={`Borrar ${worker.nombre}`}
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {editingWorker && (
        <form onSubmit={handleSave} className="mt-8 space-y-4">
          <h3 className="text-lg font-medium">Editar Trabajador</h3>
          <div>
            <label htmlFor="nombre" className="block text-sm font-medium text-gray-700">Nombre</label>
            <input
              type="text"
              name="nombre"
              id="nombre"
              value={editingWorker.nombre}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="telefono" className="block text-sm font-medium text-gray-700">Teléfono</label>
            <input
              type="text"
              name="telefono"
              id="telefono"
              value={editingWorker.telefono}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="rol" className="block text-sm font-medium text-gray-700">Rol</label>
            <input
              type="text"
              name="rol"
              id="rol"
              value={editingWorker.rol}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Guardar Cambios
          </button>
        </form>
      )}

      {!isAddingWorker ? (
        <button
          onClick={() => setIsAddingWorker(true)}
          className="mt-4 inline-flex items-center text-sm text-gray-500 hover:text-gray-700"
        >
          <PlusIcon className="h-5 w-5 mr-1" />
          Agregar nuevo trabajador
        </button>
      ) : (
        <form onSubmit={handleAddWorker} className="mt-4 space-y-4">
          <h3 className="text-lg font-medium">Agregar Nuevo Trabajador</h3>
          <div className="flex space-x-4">
            <input
              type="text"
              name="nombre"
              placeholder="Nombre"
              value={newWorker.nombre}
              onChange={handleNewWorkerInputChange}
              className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
            <input
              type="text"
              name="telefono"
              placeholder="Teléfono"
              value={newWorker.telefono}
              onChange={handleNewWorkerInputChange}
              className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
            <input
              type="text"
              name="rol"
              placeholder="Rol"
              value={newWorker.rol}
              onChange={handleNewWorkerInputChange}
              className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={() => setIsAddingWorker(false)}
              className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Agregar
            </button>
          </div>
        </form>
      )}
    </div>
  )
}