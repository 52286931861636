// components/TaskManager.js

import React, { useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import Sidebar from '../../components/dashboard/Sidebar'
import Header from '../../components/dashboard/Header'
import Navigation from '../../components/dashboard/Navigation'
import MainView from '../../components/dashboard/MainView'
import GanttChart from '../../components/dashboard/GanttChart'
import EmpresaView from '../../components/dashboard/EmpresaView'

const generateId = () => Math.random().toString(36).substr(2, 9)

const TaskManager = () => {
  const [boards, setBoards] = useState([
    {
      id: 'board1',
      title: 'Campo 1',
      processes: [
        {
          id: 'process1',
          title: 'To Do',
          tasks: [
            { id: generateId(), task: 'Task 1', status: 'Pending', person: 'John', startDate: '2023-06-01', endDate: '2023-06-05', priority: 'Bajo' },
            { id: generateId(), task: 'Task 2', status: 'Done', person: 'Jane', startDate: '2023-06-02', endDate: '2023-06-02', priority: 'Medio' },
          ],
          isEditing: false,
          newTaskContent: '',
        },
        {
          id: 'process2',
          title: 'In Progress',
          tasks: [
            { id: generateId(), task: 'Task 3', status: 'Pending', person: 'Bob', startDate: '2023-06-03', endDate: '2023-06-10', priority: 'Alto' },
          ],
          isEditing: false,
          newTaskContent: '',
        },
        {
          id: 'process3',
          title: 'Done',
          tasks: [],
          isEditing: false,
          newTaskContent: '',
        },
      ],
    },
    {
      id: 'board2',
      title: 'Campo 2',
      processes: [
        {
          id: 'process4',
          title: 'To Do',
          tasks: [],
          isEditing: false,
          newTaskContent: '',
        },
        {
          id: 'process5',
          title: 'In Progress',
          tasks: [],
          isEditing: false,
          newTaskContent: '',
        },
        {
          id: 'process6',
          title: 'Done',
          tasks: [],
          isEditing: false,
          newTaskContent: '',
        },
      ],
    },
  ])
  
  const [registers, setRegisters] = useState([
    { 
      id: 'reg1', 
      task: 'Register Task 1', 
      status: 'Done', 
      person: 'Alice', 
      startDate: '2023-06-04',
      endDate: '2023-06-08',
      priority: 'Medio'
    },
    { 
      id: 'reg2', 
      task: 'Register Task 2', 
      status: 'Pending', 
      person: 'Charlie', 
      startDate: '2023-06-05',
      endDate: '2023-06-05',
      priority: 'Bajo'
    },
  ])
  
    const [newRegisterTask, setNewRegisterTask] = useState('')
    const [openStatusDropdown, setOpenStatusDropdown] = useState(null)
    const [openPriorityDropdown, setOpenPriorityDropdown] = useState(null)
    const [view, setView] = useState('main') // 'main', 'gantt', or 'empresa'
    const [currentDate, setCurrentDate] = useState(new Date())
    const [sidebarOpen, setSidebarOpen] = useState(true)
    const [selectedBoard, setSelectedBoard] = useState(boards[0].id)
  
    const toggleSidebar = () => {
      setSidebarOpen(!sidebarOpen)
    }
  
    const onDragEnd = (result) => {
      const { source, destination, type } = result
  
      if (!destination) return
  
      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      ) {
        return
      }
  
      if (type === 'board') {
        const newBoards = Array.from(boards)
        const [reorderedBoard] = newBoards.splice(source.index, 1)
        newBoards.splice(destination.index, 0, reorderedBoard)
        setBoards(newBoards)
        return
      }
  
      if (type === 'process') {
        const boardIndex = boards.findIndex(board => board.id === selectedBoard)
        const newProcesses = Array.from(boards[boardIndex].processes)
        const [reorderedProcess] = newProcesses.splice(source.index, 1)
        newProcesses.splice(destination.index, 0, reorderedProcess)
        const newBoards = [...boards]
        newBoards[boardIndex] = { ...newBoards[boardIndex], processes: newProcesses }
        setBoards(newBoards)
        return
      }
  
      if (source.droppableId === 'registers') {
        // Moving from registers to a process
        const sourceRegisters = Array.from(registers)
        const [movedRegister] = sourceRegisters.splice(source.index, 1)
        setRegisters(sourceRegisters)
  
        setBoards(prevBoards => {
          const boardIndex = prevBoards.findIndex(board => board.id === selectedBoard)
          const processIndex = prevBoards[boardIndex].processes.findIndex(process => process.id === destination.droppableId)
          const newTasks = Array.from(prevBoards[boardIndex].processes[processIndex].tasks)
          newTasks.splice(destination.index, 0, movedRegister)
          const newProcesses = [...prevBoards[boardIndex].processes]
          newProcesses[processIndex] = { ...newProcesses[processIndex], tasks: newTasks }
          const newBoards = [...prevBoards]
          newBoards[boardIndex] = { ...newBoards[boardIndex], processes: newProcesses }
          return newBoards
        })
      } else {
        // Moving between processes or within the same process
        setBoards(prevBoards => {
          const boardIndex = prevBoards.findIndex(board => board.id === selectedBoard)
          const newProcesses = prevBoards[boardIndex].processes.map(process => {
            if (process.id === source.droppableId) {
              const newTasks = Array.from(process.tasks)
              const [movedTask] = newTasks.splice(source.index, 1)
              if (process.id === destination.droppableId) {
                // Moving within the same process
                newTasks.splice(destination.index, 0, movedTask)
              }
              return { ...process, tasks: newTasks }
            }
            if (process.id === destination.droppableId && process.id !== source.droppableId) {
              // Moving to a different process
              const newTasks = Array.from(process.tasks)
              const sourceProcess = prevBoards[boardIndex].processes.find(p => p.id === source.droppableId)
              const movedTask = sourceProcess.tasks[source.index]
              newTasks.splice(destination.index, 0, movedTask)
              return { ...process, tasks: newTasks }
            }
            return process
          })
          const newBoards = [...prevBoards]
          newBoards[boardIndex] = { ...newBoards[boardIndex], processes: newProcesses }
          return newBoards
        })
      }
    }
  
    const addNewTask = (processId) => {
      setBoards(prevBoards => {
        const boardIndex = prevBoards.findIndex(board => board.id === selectedBoard)
        const processIndex = prevBoards[boardIndex].processes.findIndex(process => process.id === processId)
        if (prevBoards[boardIndex].processes[processIndex].newTaskContent?.trim()) {
          const newTask = {
            id: generateId(),
            task: prevBoards[boardIndex].processes[processIndex].newTaskContent,
            status: 'Pending',
            person: '',
            startDate: new Date().toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0],
            priority: 'Bajo'
          }
          const newProcesses = [...prevBoards[boardIndex].processes]
          newProcesses[processIndex] = {
            ...newProcesses[processIndex],
            tasks: [...newProcesses[processIndex].tasks, newTask],
            newTaskContent: ''
          }
          const newBoards = [...prevBoards]
          newBoards[boardIndex] = { ...newBoards[boardIndex], processes: newProcesses }
          return newBoards
        }
        return prevBoards
      })
    }
  
    const updateNewTaskContent = (processId, content) => {
      setBoards(prevBoards => {
        const boardIndex = prevBoards.findIndex(board => board.id === selectedBoard)
        const processIndex = prevBoards[boardIndex].processes.findIndex(process => process.id === processId)
        const newProcesses = [...prevBoards[boardIndex].processes]
        newProcesses[processIndex] = { ...newProcesses[processIndex], newTaskContent: content }
        const newBoards = [...prevBoards]
        newBoards[boardIndex] = { ...newBoards[boardIndex], processes: newProcesses }
        return newBoards
      })
    }
  
    const toggleEditMode = (processId) => {
      setBoards(prevBoards => {
        const boardIndex = prevBoards.findIndex(board => board.id === selectedBoard)
        const processIndex = prevBoards[boardIndex].processes.findIndex(process => process.id === processId)
        const newProcesses = [...prevBoards[boardIndex].processes]
        newProcesses[processIndex] = { ...newProcesses[processIndex], isEditing: !newProcesses[processIndex].isEditing }
        const newBoards = [...prevBoards]
        newBoards[boardIndex] = { ...newBoards[boardIndex], processes: newProcesses }
        return newBoards
      })
    }
  
    const updateProcessTitle = (processId, newTitle) => {
      setBoards(prevBoards => {
        const boardIndex = prevBoards.findIndex(board => board.id === selectedBoard)
        const processIndex = prevBoards[boardIndex].processes.findIndex(process => process.id === processId)
        const newProcesses = [...prevBoards[boardIndex].processes]
        newProcesses[processIndex] = { ...newProcesses[processIndex], title: newTitle }
        const newBoards = [...prevBoards]
        newBoards[boardIndex] = { ...newBoards[boardIndex], processes: newProcesses }
        return newBoards
      })
    }
  
    const finishEditingProcessTitle = (processId) => {
      setBoards(prevBoards => {
        const boardIndex = prevBoards.findIndex(board => board.id === selectedBoard)
        const processIndex = prevBoards[boardIndex].processes.findIndex(process => process.id === processId)
        const newProcesses = [...prevBoards[boardIndex].processes]
        newProcesses[processIndex] = { ...newProcesses[processIndex], isEditing: false }
        const newBoards = [...prevBoards]
        newBoards[boardIndex] = { ...newBoards[boardIndex], processes: newProcesses }
        return newBoards
      })
    }
    
    const addBoard = () => {
      setBoards([...boards, { id: generateId(), title: 'New Board', processes: [] }])
    }

    const handleKeyDown = (e, processId) => {
      if (e.key === 'Enter') {
        finishEditingProcessTitle(processId)
      }
    }
  
    const addNewProcess = () => {
      setBoards(prevBoards => {
        const boardIndex = prevBoards.findIndex(board => board.id === selectedBoard)
        const newProcess = {
          id: generateId(),
          title: "New Process",
          tasks: [],
          isEditing: false,
          newTaskContent: '',
        }
        const newProcesses = [...prevBoards[boardIndex].processes, newProcess]
        const newBoards = [...prevBoards]
        newBoards[boardIndex] = { ...newBoards[boardIndex], processes: newProcesses }
        return newBoards
      })
    }
  
    const addNewRegister = () => {
      if (!newRegisterTask.trim()) return
      const today = new Date().toISOString().split('T')[0]
      const newRegister = {
        id: generateId(),
        task: newRegisterTask,
        status: 'Pending',
        person: '',
        startDate: today,
        endDate: today,
        priority: 'Bajo'
      }
      setRegisters([...registers, newRegister])
      setNewRegisterTask('')
    }
  
    const deleteProcess = (processId) => {
      setBoards(prevBoards => {
        const boardIndex = prevBoards.findIndex(board => board.id === selectedBoard)
        const newProcesses = prevBoards[boardIndex].processes.filter(process => process.id !== processId)
        const newBoards = [...prevBoards]
        newBoards[boardIndex] = { ...newBoards[boardIndex], processes: newProcesses }
        return newBoards
      })
    }
  
    const toggleStatusDropdown = (taskId) => {
      setOpenStatusDropdown(openStatusDropdown === taskId ? null : taskId)
      setOpenPriorityDropdown(null)
    }
  
    const togglePriorityDropdown = (taskId) => {
      setOpenPriorityDropdown(openPriorityDropdown === taskId ? null : taskId)
      setOpenStatusDropdown(null)
    }
  
    const updateTaskStatus = (processId, taskId, newStatus) => {
      setBoards(prevBoards => {
        const boardIndex = prevBoards.findIndex(board => board.id === selectedBoard)
        const processIndex = prevBoards[boardIndex].processes.findIndex(process => process.id === processId)
        const newTasks = prevBoards[boardIndex].processes[processIndex].tasks.map(task =>
          task.id === taskId ? { ...task, status: newStatus } : task
        )
        const newProcesses = [...prevBoards[boardIndex].processes]
        newProcesses[processIndex] = { ...newProcesses[processIndex], tasks: newTasks }
        const newBoards = [...prevBoards]
        newBoards[boardIndex] = { ...newBoards[boardIndex], processes: newProcesses }
        return newBoards
      })
      setOpenStatusDropdown(null)
    }
  
    const updateTaskPriority = (processId, taskId, newPriority) => {
      setBoards(prevBoards => {
        const boardIndex = prevBoards.findIndex(board => board.id === selectedBoard)
        const processIndex = prevBoards[boardIndex].processes.findIndex(process => process.id === processId)
        const newTasks = prevBoards[boardIndex].processes[processIndex].tasks.map(task =>
          task.id === taskId ? { ...task, priority: newPriority } : task
        )
        const newProcesses = [...prevBoards[boardIndex].processes]
        newProcesses[processIndex] = { ...newProcesses[processIndex], tasks: newTasks }
        const newBoards = [...prevBoards]
        newBoards[boardIndex] = { ...newBoards[boardIndex], processes: newProcesses }
        return newBoards
      })
      setOpenPriorityDropdown(null)
    }

    return (
      <div className="flex h-screen bg-gray-100">
        <Sidebar
          boards={boards}
          selectedBoard={selectedBoard}
          setSelectedBoard={setSelectedBoard}
          sidebarOpen={sidebarOpen}
          toggleSidebar={toggleSidebar}
          addBoard={addBoard}
        />
        <div className="flex-1 overflow-auto">
          <div className="p-4">
            <Header
              selectedBoard={selectedBoard}
              boards={boards}
            />
            <Navigation
              view={view}
              setView={setView}
            />
            <DragDropContext onDragEnd={onDragEnd}>
              {view === 'main' && (
                <MainView
                  boards={boards}
                  selectedBoard={selectedBoard}
                  registers={registers}
                  addNewProcess={addNewProcess}
                  addNewRegister={addNewRegister}
                  newRegisterTask={newRegisterTask}
                  setNewRegisterTask={setNewRegisterTask}
                  toggleStatusDropdown={toggleStatusDropdown}
                  togglePriorityDropdown={togglePriorityDropdown}
                  updateTaskStatus={updateTaskStatus}
                  updateTaskPriority={updateTaskPriority}
                  openStatusDropdown={openStatusDropdown}
                  openPriorityDropdown={openPriorityDropdown}
                  toggleEditMode={toggleEditMode}
                  updateProcessTitle={updateProcessTitle}
                  finishEditingProcessTitle={finishEditingProcessTitle}
                  handleKeyDown={handleKeyDown}
                  deleteProcess={deleteProcess}
                  addNewTask={addNewTask}
                  updateNewTaskContent={updateNewTaskContent}
                />
              )}
              {view === 'gantt' && (
                <GanttChart
                  boards={boards}
                  registers={registers}
                  currentDate={currentDate}
                  setCurrentDate={setCurrentDate}
                  selectedBoard={selectedBoard}
                  
                />
              )}
              {view === 'empresa' && <EmpresaView />}
            </DragDropContext>
          </div>
        </div>
      </div>
    )
}

export default TaskManager