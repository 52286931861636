// components/Process.js

import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { GripVertical, Edit2, Check, Trash2, Plus } from 'lucide-react'
import Task from './Task'


const statusOptions = [
    { value: 'Done', color: 'bg-green-500' },
    { value: 'Pending', color: 'bg-yellow-500' },
  ]
  
  const priorityOptions = [
    { value: 'Alto', color: 'bg-red-500' },
    { value: 'Medio', color: 'bg-yellow-500' },
    { value: 'Bajo', color: 'bg-blue-500' },
  ]

const Process = ({
    process,
    index,
    toggleStatusDropdown,
    togglePriorityDropdown,
    updateTaskStatus,
    updateTaskPriority,
    openStatusDropdown,
    openPriorityDropdown,
    toggleEditMode,
    updateProcessTitle,
    finishEditingProcessTitle,
    handleKeyDown,
    deleteProcess,
    addNewTask,
    updateNewTaskContent,
    addNewProcess
}) => {
  return (
    <Draggable key={process.id} draggableId={process.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`bg-white rounded-lg shadow-md p-4 ${snapshot.isDragging ? 'border-2 border-blue-500' : ''}`}
        >
          <div className="flex justify-between items-center mb-2">
            <div {...provided.dragHandleProps} className="flex items-center cursor-move">
              <GripVertical className="w-5 h-5 mr-2 text-gray-400" />
              {process.isEditing ? (
                <input
                  type="text"
                  value={process.title}
                  onChange={(e) => updateProcessTitle(process.id, e.target.value)}
                  onBlur={() => finishEditingProcessTitle(process.id)}
                  onKeyDown={(e) => handleKeyDown(e, process.id)}
                  className="font-semibold border rounded px-2 py-1"
                  autoFocus
                />
              ) : (
                <h2 className="text-lg font-semibold">{process.title}</h2>
              )}
            </div>
            <div className="flex items-center space-x-2">
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => toggleEditMode(process.id)}
              >
                {process.isEditing ? <Check className="w-4 h-4" /> : <Edit2 className="w-4 h-4" />}
              </button>
              <button
                className="text-red-600 hover:text-red-800"
                onClick={() => deleteProcess(process.id)}
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </div>
          </div>
          <Droppable droppableId={process.id} type="task">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="border p-2"></th>
                      <th className="border p-2 text-left">Task</th>
                      <th className="border p-2">Status</th>
                      <th className="border p-2">Person</th>
                      <th className="border p-2">Start Date</th>
                      <th className="border p-2">End Date</th>
                      <th className="border p-2">Priority</th>
                      <th className="border p-2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {process.tasks.map((task, index) => (
                      <Task
                        key={task.id}
                        task={task}
                        index={index}
                        processId={process.id}
                        statusOptions={statusOptions}
                        priorityOptions={priorityOptions}
                        toggleStatusDropdown={toggleStatusDropdown}
                        togglePriorityDropdown={togglePriorityDropdown}
                        updateTaskStatus={updateTaskStatus}
                        updateTaskPriority={updateTaskPriority}
                        openStatusDropdown={openStatusDropdown}
                        openPriorityDropdown={openPriorityDropdown}
                        
                      />
                    ))}
                  </tbody>
                </table>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="mt-2 flex space-x-2">
            <input
              type="text"
              placeholder="+ Add Task"
              value={process.newTaskContent}
              onChange={(e) => updateNewTaskContent(process.id, e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && addNewTask(process.id)}
              className="flex-grow border rounded px-2 py-1"
            />
            <button
              onClick={() => addNewTask(process.id)}
              className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
            >
              Add
            </button>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default Process