// components/Task.js

import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { GripVertical, MessageSquare, Plus } from 'lucide-react'


const Task = ({
  task,
  index,
  processId,
  toggleStatusDropdown,
  togglePriorityDropdown,
  updateTaskStatus,
  updateTaskPriority,
  openStatusDropdown,
  openPriorityDropdown,
  statusOptions,
  priorityOptions
}) => {
  return (
    <Draggable key={task.id} draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`hover:bg-gray-50 ${snapshot.isDragging ? 'bg-blue-100' : ''}`}
        >
          <td className="border p-2">
            <input type="checkbox" />
          </td>
          <td className="border p-2 flex items-center">
            <span {...provided.dragHandleProps}>
              <GripVertical className="w-4 h-4 mr-2 text-gray-400 cursor-move" />
            </span>
            {task.task}
            <MessageSquare className="w-4 h-4 ml-2 text-gray-400" />
          </td>
          <td className="border p-2 relative">
            <button
              className={`w-full text-left px-2 py-1 rounded ${statusOptions.find(option => option.value === task.status)?.color || 'bg-gray-300'}`}
              onClick={() => toggleStatusDropdown(task.id)}
            >
              {task.status || 'Set status'}
            </button>
            {openStatusDropdown === task.id && (
              <div className="absolute z-10 mt-1 w-full bg-white border rounded shadow-lg">
                {statusOptions.map((option) => (
                  <button
                    key={option.value}
                    className={`w-full text-left px-2 py-1 hover:bg-gray-100 ${option.color}`}
                    onClick={() => updateTaskStatus(processId, task.id, option.value)}
                  >
                    {option.value}
                  </button>
                ))}
              </div>
            )}
          </td>
          <td className="border p-2">{task.person}</td>
          <td className="border p-2">{task.startDate}</td>
          <td className="border p-2">
            {task.startDate === task.endDate ? (
              <span className="text-gray-500">No end date</span>
            ) : (
              task.endDate
            )}
          </td>
          <td className="border p-2 relative">
            <button
              className={`w-full text-left px-2 py-1 rounded ${priorityOptions.find(option => option.value === task.priority)?.color || 'bg-gray-300'}`}
              onClick={() => togglePriorityDropdown(task.id)}
            >
              {task.priority || 'Set priority'}
            </button>
            {openPriorityDropdown === task.id && (
              <div className="absolute z-10 mt-1 w-full bg-white border rounded shadow-lg">
                {priorityOptions.map((option) => (
                  <button
                    key={option.value}
                    className={`w-full text-left px-2 py-1 hover:bg-gray-100 ${option.color}`}
                    onClick={() => updateTaskPriority(processId, task.id, option.value)}
                  >
                    {option.value}
                  </button>
                ))}
              </div>
            )}
          </td>
          <td className="border p-2">
            <Plus className="w-4 h-4 text-gray-400" />
          </td>
        </tr>
      )}
    </Draggable>
  )
}

export default Task