// components/Header.js

import React from 'react'
import { Star, Clock, Users } from 'lucide-react'

const Header = ({ selectedBoard, boards }) => {
  const currentBoard = boards.find(board => board.id === selectedBoard)

  return (
    <div className="flex justify-between items-center mb-4">
      <div className="flex items-center space-x-2">
        <h1 className="text-2xl font-bold">{currentBoard?.title}</h1>
        <Clock className="w-5 h-5 text-gray-500" />
        <Star className="w-5 h-5 text-yellow-400" />
      </div>
      <div className="flex items-center space-x-4">
        <span className="flex items-center">
          <Clock className="w-4 h-4 mr-1" />
          Last seen
          <div className="w-6 h-6 rounded-full bg-gray-300 ml-1"></div>
          <span className="text-gray-600 ml-1">+2</span>
        </span>
        <span className="flex items-center">
          <Users className="w-4 h-4 mr-1" />
          Invite / 1
        </span>
      </div>
    </div>
  )
}

export default Header