// components/Sidebar.js

import React from 'react'
import { Menu, X, Plus } from 'lucide-react'

const Sidebar = ({ boards, selectedBoard, setSelectedBoard, sidebarOpen, toggleSidebar, addBoard }) => {
  return (
    <div className={`bg-white text-black ${sidebarOpen ? 'w-52' : 'w-16'} transition-all duration-300 ease-in-out`}>
      <div className="p-4 flex justify-between items-center">
        <h2 className={`font-bold ${sidebarOpen ? '' : 'hidden'}`}>Boards</h2>
        <button onClick={toggleSidebar} className="text-black">
          {sidebarOpen ? <X /> : <Menu />}
        </button>
      </div>
      <nav>
        {boards.map(board => (
          <button
            key={board.id}
            onClick={() => setSelectedBoard(board.id)}
            className={`w-full text-left p-4 hover:bg-gray-200 ${selectedBoard === board.id ? 'bg-gray-100' : ''}`}
          >
            {sidebarOpen ? board.title : `${board.title[0]} ${board.title.split(' ')[1]}`}
          </button>
        ))}
      </nav>
      {sidebarOpen && (
        <div className="p-2">
          <button 
            onClick={addBoard} 
            className="flex items-center justify-center w-full text-left p-2 hover:bg-gray-200 bg-gray-100 rounded-md">
            <Plus className="w-4 h-4" />
          </button>
        </div>
      )}
    </div>
  )
}

export default Sidebar
