import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import WebSocket from 'websocket';


function ChartPage() {
    const [data, setData] = useState(null);
    const [numLabels, setNumLabels] = useState(5);
    const [sensorId, setSensorId] = useState('52b818eb');
    const [valores, setValores] = useState(null);
    const [fechas, setFechas] = useState(null);
    

    const socket = new window.WebSocket("wss://data.miido.cl");
    useEffect(() => {
        socket.addEventListener('open', (event) => {
          socket.send(`get:${sensorId}:${numLabels}`);
        });
        socket.addEventListener('message', (event) => {
          const response = event.data;
          const subcadenas = response.slice(2, -2).split("], ['");
          // Convierte las subcadenas en objetos
          const listaDeObjetos = subcadenas.map(subcadena => {
            const elementos = subcadena.split(", ");
            const id = elementos[0].slice(1,-1);
            const fecha = elementos[1].slice(1,-1);
            const valor = parseFloat(elementos[2]);
            return { id, fecha, valor };
          });
          const labels = listaDeObjetos.map(objeto => objeto.fecha);
          const valores = listaDeObjetos.map(objeto => objeto.valor);
          setValores(valores);
          setFechas(labels);
          console.log(listaDeObjetos);
          });
      }, [numLabels, sensorId]);

      const ChartData = {
        labels: fechas,
        datasets : [
          {
            label: `Data sensor ${sensorId}`,
            data: valores,
            fill: false,
            backgroundColor: '#87bdb9',

          },
        ],
      };

      //charts
      
      
      
    
    
    const handleNumLabelsChange = (event) => {
        
        setNumLabels(parseInt(event.target.value)); // Actualiza la cantidad de etiquetas
      };
    const handleSensorIdChange = (event) => {
        
        setSensorId(event.target.value); // Actualiza la cantidad de etiquetas
    };

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={1}>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={numLabels}
                    label="numlabels"
                    onChange={handleNumLabelsChange}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={1}>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sensorId}
                    label="url"
                    onChange={handleSensorIdChange}
                    >
                        <MenuItem value={'52b818eb'}>N</MenuItem>
                        <MenuItem value={'73f088bf'}>P</MenuItem>
                        <MenuItem value={'c9c56568'}>K</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            
            {valores && fechas ? <Line data={ChartData} /> : <p>loading...</p>}
            
            </div>
    );
}

export default ChartPage;
