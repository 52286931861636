// components/Navigation.js

import React from 'react'

const Navigation = ({ view, setView }) => {
  return (
    <div className="flex space-x-4 mb-4">
      <button 
        className={`px-3 py-1 rounded ${view === 'main' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        onClick={() => setView('main')}
      >
        Main Table
      </button>
      <button 
        className={`px-3 py-1 rounded ${view === 'gantt' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        onClick={() => setView('gantt')}
      >
        Gantt Chart
      </button>
      <button 
        className={`px-3 py-1 rounded ${view === 'empresa' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        onClick={() => setView('empresa')}
      >
        Empresa
      </button>
    </div>
  )
}

export default Navigation