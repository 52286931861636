// @mui
import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  Tab,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import { Helmet } from "react-helmet-async";
import Table from '@mui/material/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Tabs from '@mui/material/Tabs';
import LinearProgress from '@mui/material/LinearProgress';
import { visuallyHidden } from '@mui/utils';
// components
// sections
import React from "react";
import { useEffect, useState } from "react";
import { Line, Pie, Bar } from "react-chartjs-2";
import dotenv from 'dotenv';
import Swal from "sweetalert2";
import "./form.css"

dotenv.config();
const url = process.env.REACT_APP_BASE_URL;

// --------------------- tareas ----------------------
//  id   Empresa_id  User_id  titulo   fecha  fecha_termino   estado  prioridad   tipo_tarea  id_imagen

// graficos

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'estado') {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  } else if (orderBy === 'prioridad') {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  } else if (orderBy === 'fecha') {
    if (new Date(b[orderBy]) < new Date(a[orderBy])) {
      return -1;
    }
    if (new Date(b[orderBy]) > new Date(a[orderBy])) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
};

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
};

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export default function DashboardTask() {
  if (!localStorage.getItem('jwt')) {
    window.location.href = "/login";
  }
  const [barData, setBarData] = useState({labels: [], datasets: []});
  const [lineData, setLineData] = useState({labels: [], datasets: []});
  const [lineaData, setLineaData] = useState({labels: [], datasets: []});
  const [pieData, setPieData] = useState({labels: [], datasets: []});
  const [cantidadHoras, setCantidadHoras] = useState(0);
  const [calculos, setCalculos] = useState([]);
  const [employee, setEmployee] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [Empresa_id, setEmpresa_id] = useState(0);
  const [filteredTareas, setFilteredTareas] = useState([]);
  const [issues, setIssues] = useState([]);
  const [jwt, setJwt] = useState("");
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false); // Estado para el popup
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('prioridad');
  const [priority, setPriority] = useState(5);
  const [selectedImage, setSelectedImage] = useState(null); // Estado para la imagen seleccionada
  const [state, setState] = useState(5);
  const [tabValue, setTabValue] = useState(1);
  const [tareas, setTareas] = useState([]);
  const [total, setTotal] = useState(0);
  const [valorHora, setValorHora] = useState(0);
  
  const jwt_ = localStorage.getItem('jwt');
  const data = jwt_.split('.')[1];
  const user = JSON.parse(atob(data));
  const User_id = user.User_id;

  const calcularTotal = () => {
    const total = valorHora * cantidadHoras;
    setTotal(total);
  };

  /* Formato YYYY-MM-DDTHH:MM:SS'signo'HH:MM */
  const ISO8601 = (fechaUTC) => {
    if (fechaUTC === null) return "Sin asignar"; 
    const date = new Date(fechaUTC);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    //const offset = parseInt(localStorage.getItem('offset'), 10);
    //const offsetSign = offset >= 0 ? '+' : '-';
    //const offsetHours = String(Math.abs(offset)).padStart(2, '0');
    //return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offsetSign}${offsetHours}:00`;
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };
  
  const rgbToRgbaString = (rgb, opacity) => {
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
  };

  const fixDate = (oldDate, type) => {
    if (oldDate === "Sin asignar") return oldDate;
    const date = new Date(oldDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Los meses en JavaScript empiezan en 0
    const year = date.getFullYear();
    if (type === "Swal") return `${year}-${month}-${day}`;
    return `${day}-${month}-${year}`;
  };

  const fixPriority = (state) => {
    if (parseInt(state) === 0 || state === "") {
      return "Sin asignar";
    } else if (parseInt(state) === 1) {
      return "Bajo";
    } else if (parseInt(state) === 2) {
      return "Medio"
    } else {
      return "Urgente";
    }
  };

  const fixState = (state) => {
    if (parseInt(state) === 0) {
      return "Sin comenzar";
    } else if (parseInt(state) === 1) {
      return "Comenzada";
    } else {
      return "Finalizada";
    }
  };

  const filterData = (tareas) => {
    const labels = [];
    const data = [];
    tareas.forEach((tarea) => {
      labels.push(tarea.id);
      data.push(tarea.Empresa_id);
    });
    setLineaData({
      labels: labels,
      datasets: [
        {
          label: 'Tareas',
          data: data,
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
        },
      ],
    });
  };

  const filterPieData = (tareas) => {
    try {
      const data = [];
      let sinComenzar = 0;
      let finalizada = 0;
      tareas.forEach((tarea) => {
        if (parseInt(tarea.estado) === 0) {
          sinComenzar++;
        } 
        else if (parseInt(tarea.estado) === 2) {
          finalizada++;
        }
      });
      data.push(sinComenzar);
      data.push(finalizada);
      const labels = ['Sin comenzar',  'Finalizada'];
      const backgroundColor = {
        SinComenzar: 'rgba(81, 160, 154, 0.5)',
        Finalizada: 'rgba(194, 219, 100, 0.7)',
      };
      const borderColor = {
        SinComenzar: 'rgba(81, 160, 154, 1)',
        Finalizada: 'rgba(194, 219, 100, 1)',
      }
      const borderWidth = 1;
      const datasets = [
        {
          label: 'Tareas',
          data: data,
          backgroundColor: [backgroundColor.SinComenzar, backgroundColor.Finalizada],
          borderColor: [borderColor.SinComenzar, borderColor.Finalizada],
          borderWidth: borderWidth,
        },
      ];
      setPieData({
        labels: labels,
        datasets: datasets,
      });
    } catch (error) {
      console.error('Error al procesar los datos de pie:', error);
    }
  };

  const filterLineData = (data_issues) => {
    try {
      const data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const today = new Date();
      const dates = [];
      for (let i = 7; i > 0; i--) {
        const pastDate = new Date(today);
        pastDate.setDate(today.getDate() - i);
        dates.push(formatDate(pastDate));
      }
  
      dates.push(formatDate(today));
  
      for (let i = 1; i <= 2; i++) {
        const futureDate = new Date(today);
        futureDate.setDate(today.getDate() + i);
        dates.push(formatDate(futureDate));
      }
  
      for (let i = 0; i < data_issues.length; i++) {
        for (let j = 0; j < dates.length; j++) {
          if (formatDate(new Date(data_issues[i].fecha)) === dates[j]) {
            data[j] += 1
          }
        }
      }
  
      const borderWidth = 1;
      const datasets = [
        {
          label: 'Incidencias',
          data: data,
          borderWidth: borderWidth,
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)"
        },
      ];
      setLineData({
        labels: dates,
        datasets: datasets,
      });
    } catch (error) {
      console.error('Error al procesar los datos de línea:', error);
    }
  };

  const filterBarData = (data_issues) => {
    try {
      const issues_name = [];
      const issues_amount = [];

      const count = {};

      data_issues.forEach(item => {
        if (item.categoria === null) {
          item.categoria = 'Sin categoria';
        }
        const categoria = item.categoria;
        count[categoria] = (count[categoria] || 0) + 1;
      });

      for (const [key, value] of Object.entries(count)) {
        issues_name.push(key);
        issues_amount.push(value);
      }
      
      const hexColors = ['#38507E', '#51A09A', '#C2DB64', '#2F3D44'];

      const backgroundColor = {};
      const borderColor = {};
      const borderWidth = 1;

      issues_name.forEach((issue, index) => {
        const hexColor = hexColors[index % hexColors.length];
        const rgbColor = hexToRgb(hexColor);
        backgroundColor[issue] = rgbToRgbaString(rgbColor, 0.5);
        borderColor[issue] = rgbToRgbaString(rgbColor, 1);
      });

      const datasets = [
        {
          label: 'Cantidad',
          data: issues_amount,
          borderWidth: borderWidth,
          fill: true,
          backgroundColor: issues_name.map(issue => backgroundColor[issue]),
          borderColor: issues_name.map(issue => borderColor[issue]),
          barPercentage: 0.5,
          maxBarThickness: 50,
          minBarLength: 2,
        },
      ];

      setBarData({
        labels: issues_name,
        datasets: datasets,
      });
    } catch (error) {
      console.error('Error al procesar los datos de línea:', error);
    }
  };

  const formatDate = (inputDate) => {
    const dateString = inputDate instanceof Date ? inputDate.toISOString() : inputDate;
    const [year, month, day] = dateString.split('T')[0].split('-');
    return `${day}-${month}`;
  };

  const handleButton = () => {
    console.log("valorHora", valorHora);
    console.log("cantidadHoras", cantidadHoras);
    console.log("total", total);
    setCalculos([...calculos, { valorHora, cantidadHoras, total }]);
  };

  const handleCantidadHoras = (event) => {
    setCantidadHoras(event.target.value);
    setTotal(event.target.value * valorHora);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const handleDelete = (id) => {
    console.log("id", id);
    console.log("jwt", jwt);
    console.log("url",`${url}/tareas/delete/${id}`)
    fetch(`${url}/tareas/delete/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'auth': jwt
      },
    }).then(response => response.json())
      .then(data => {
        setTareas(tareas.filter(tarea => tarea.id !== id));
        setFilteredTareas(tareas.filter(tarea => tarea.id !== id));
      })
      .catch(error => {
        console.error('Error:', error);
      });

  };

  const handleEdit = async (id, title, date, enddate, state, type) => {
    const { value: formValues } = await Swal.fire({
      title: `Editar tarea "${title}"`,
      html: `
        <label for="swal-title" class="form-label">Título</label>
        <input id="swal-title" class="form-input" type="text" value="${title}" required>
        <label for="swal-date" class="form-label">Fecha</label>
        <input id="swal-date" class="form-input" type="date" value="${fixDate(date, "Swal")}">
        ${
          (enddate === "Sin asignar") ? 
          '' : 
          '<label for="swal-enddate" class="form-label">Fecha de termino</label><input id="swal-enddate" class="form-input" type="date" value="'+fixDate(enddate, "Swal")+'">'
        }
        <label for="swal-state" class="form-label">Estado</label>
        <select id="swal-state" class="form-select">
          <option value="0" ${(parseInt(state) === 0) ? "selected" : ""}>Sin comenzar</option>
          <option value="1" ${(parseInt(state) === 1) ? "selected" : ""}>Comenzada</option>
          <option value="2" ${(parseInt(state) === 2) ? "selected" : ""}>Finalizada</option>
        </select>
        <label for="swal-type" class="form-label">Descripción</label>
        <input id="swal-type" class="form-input" type="text" value="${type}">
      `,
      showCancelButton: true,
      confirmButtonText: "Editar tarea",
      cancelButtonText: "Cancelar",
      focusConfirm: false,
      preConfirm: () => {
        if (document.getElementById("swal-title").value && document.getElementById("swal-date").value && document.getElementById("swal-type").value) {
          if (enddate === "Sin asignar") {
            return [
              document.getElementById("swal-title").value,
              document.getElementById("swal-date").value,
              document.getElementById("swal-state").value,
              document.getElementById("swal-type").value,
            ]
          } else {
            return [
              document.getElementById("swal-title").value,
              document.getElementById("swal-date").value,
              document.getElementById("swal-enddate").value,
              document.getElementById("swal-state").value,
              document.getElementById("swal-type").value,
            ]
          }  
        } else {
          Swal.showValidationMessage('Campo del formulario requerido')   
        }
      }
    });
    if (formValues) {
      const date_aux = new Date();
      const time = `${date_aux.getHours()}:${date_aux.getMinutes()}:${date_aux.getSeconds()}`;
      let data;

      if (formValues.length === 5) {
        data = {
          titulo: formValues[0],
          fecha: formValues[1],
          fecha_termino: (formValues[2] === '') ? null : formValues[2],
          estado: formValues[3],
          tipo_tarea: formValues[4],
          tiempo: (fixDate(date, "Swal") === formValues[1]) ? null : time,
          tiempo_termino: (fixDate(enddate, "Swal") === formValues[2]) ? null : time,
          fecha_original: date,
          fecha_termino_original: enddate
        };
      } else if (formValues.length === 4) {
        data = {
          titulo: formValues[0],
          fecha: formValues[1],
          estado: formValues[2],
          tipo_tarea: formValues[3],
          tiempo: (fixDate(date, "Swal") === formValues[1]) ? null : time,
          fecha_original: date,
        };
      }

      await fetch(`${url}/tareas/put/data/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'auth': jwt
        },
        body: JSON.stringify(data)
      });

      const info = setupJwt();
      let tareas = {}
      await fetch(`${url}/tareas/${info.Empresa_id}`, {
        method: 'GET',
        headers: {
          'auth': info.jwt,
          'mode': 'no-cors',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((data) => {
        const adjustedData = data["data"].map(tarea => {
          return {
            ...tarea,
            fecha: ISO8601(tarea.fecha),
            fecha_termino: ISO8601(tarea.fecha_termino)
          }
        });
        setTareas(adjustedData);
        if (employee !== 0) {
          const newTareas = adjustedData.filter(tarea => parseInt(tarea.User_id) === employee);
          setFilteredTareas(newTareas);
        } else {
          setFilteredTareas(adjustedData);
        }
        
        setLoading(false);
        tareas = adjustedData;
        filterData(tareas);
        filterPieData(tareas);
      });

      Swal.fire({
        title: 'Tarea editada!',
        text: 'La tarea ha sido actualizada con éxito',
        icon: 'info',
        confirmButtonText: 'Ok'
      });
    }
  };

  const handleEmployee = (e) => {
    if (e.target.value === 0) {
        setEmployee(0);
        setFilteredTareas(tareas);
        return;
    }
    setEmployee(e.target.value);
  };

  const handlePriority = (e) => {
    if (e.target.value === 5) {
        setPriority(5);
        setFilteredTareas(tareas);
        return;
    }
    setPriority(e.target.value);
  };

  const handleState = (e) => {
    if (e.target.value === 5) {
        setState(5);
        setFilteredTareas(tareas);
        return;
    }
    setState(e.target.value);
  };

  const handleImageClick = (url) => {
    setSelectedImage(url);
    setOpen(true);
  };

  const handleSave = (id, estado) => {
    fetch(`${url}/tareas/put/prioridad/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'auth': jwt
      },
      body: JSON.stringify({
        estado
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
    });
  };

  const handleImportanceChange = (e, tareaId) => {
    const newPriority = e.target.value;
    const updatedTasks = tareas.map(tarea => {
      if (tarea.id === tareaId) {
        tarea.prioridad = newPriority;
      }
      return tarea;
    });
    handleSave(tareaId, newPriority);
    setTareas(updatedTasks);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleValorHora = (event) => {
    setValorHora(event.target.value);
    setTotal(event.target.value * cantidadHoras);
  };

  const renderTable = (tareas) => {
    return tareas.map(tarea => {
      return (
        <TableRow key={tarea.id}>
          <TableCell>{tarea.empleado}</TableCell>
          <TableCell>{tarea.titulo}</TableCell>
          <TableCell>{fixDate(tarea.fecha, "")/* tarea.fecha */}</TableCell>
          <TableCell>{fixDate(tarea.fecha_termino, "")/* tarea.fecha_termino */}</TableCell>
          <TableCell>{fixState(tarea.estado)}</TableCell>
          <TableCell>
            <Select
              value={tarea.prioridad}
              label={fixPriority(tarea.prioridad)}
              onChange={(e) => handleImportanceChange(e, tarea.id)}
              sx={{
                '& .MuiSelect-select': {
                  display: 'flex',
                  padding: '3px',
                  alignItems: 'center'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
              }}
            >
              <MenuItem value={3}>
                <Chip label="Urgente" sx={{ backgroundColor: '#DD7C36', color: 'white', height: '24px' }} />
              </MenuItem>
              <MenuItem value={2}>
                <Chip label="Medio" sx={{ backgroundColor: '#FDFF85', color: 'black', paddingRight: '11px', height: '24px' }} />
              </MenuItem>
              <MenuItem value={1}>
                <Chip label="Bajo" sx={{ backgroundColor: '#B8C975', color: 'white', paddingRight: '20px', height: '24px' }} />
              </MenuItem>
              <MenuItem value={0}>
                <Chip label="Sin asignar" sx={{ backgroundColor: '#F5F5F5', color: 'black', paddingRight: '20px', height: '24px' }} />
              </MenuItem>
            </Select>
          </TableCell>
          <TableCell>{tarea.tipo_tarea}</TableCell>
          <TableCell>
            {tarea.url && tarea.url != "None" && tarea.url != "null"? (
              <img
                src={tarea.url}
                alt="imagen"
                style={{ width: 90, height: 130, cursor: 'pointer' }}
                onClick={() => handleImageClick(tarea.url)}
              />
            ) : (
              <Typography>No hay imagen disponible</Typography>
            )}
          </TableCell>
          <TableCell>
            <IconButton onClick={() => handleEdit(tarea.id, tarea.titulo, tarea.fecha, tarea.fecha_termino, tarea.estado, tarea.tipo_tarea)}>
              <EditIcon />
            </IconButton>
          </TableCell>
          <TableCell>
            <IconButton onClick={() => handleDelete(tarea.id)}>
              <RemoveIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  function setupOffset() {
    const now = new Date();
    const offsetMinutes = now.getTimezoneOffset();
    const offsetHours = offsetMinutes / 60;
    const offsetDirection = offsetHours > 0 ? '-' : '+';
    const offsetFormatted = `${offsetDirection}${Math.abs(offsetHours)}`;
    localStorage.setItem("offset", parseInt(offsetFormatted));
  };

  function setupJwt() {
    const jwt = localStorage.getItem('jwt');
    setJwt(jwt);
    const decoded = Buffer.from(jwt.split('.')[1], 'base64').toString('ascii');
    const data = JSON.parse(decoded);
    console.log("data", data);
    const response = {
      "jwt": jwt,
      "Empresa_id": data.Empresa_id
    }
    return response;
  };

  function setupWebSocket(info) {
    const uri = 'wss://websocket-eqjlrpwjoa-uc.a.run.app';
    const connection = new WebSocket(uri);

    connection.addEventListener('open', () => {
      console.log('Connected to the WebSocket server');
    });

    connection.addEventListener('message', (event) => {
      const data = event.data;
      console.log('Received the defined message:', data);
      const refreshed_id = data.split(':')[1].trim();
      if (parseInt(info.Empresa_id) === parseInt(refreshed_id)) {
        let tareas = {}
        fetch(`${url}/tareas/${info.Empresa_id}`, {
          method: 'GET',
          headers: {
            'auth': info.jwt,
            'mode': 'no-cors',
            'Content-Type': 'application/json',
          },

        })
          .then((response) => response.json())
          .then((data) => {
            const adjustedData = data["data"].map(tarea => {
              return {
                ...tarea,
                fecha: ISO8601(tarea.fecha),
                fecha_termino: ISO8601(tarea.fecha_termino)
              }
            });
            setTareas(adjustedData);
            setFilteredTareas(adjustedData);
            setLoading(false);
            tareas = adjustedData;
            filterData(tareas);
            filterPieData(tareas);
          });

        fetch(`${url}/issues/empresa/${info.Empresa_id}`, {
          method: 'GET',
          headers: {
            'auth': info.jwt,
            'mode': 'no-cors',
            'Content-Type': 'application/json',
          },
        })
          .then(response => response.json())
          .then(data => {
            setIssues(data);
            filterLineData(data);
            filterBarData(data);
          })
          .catch(error => {
            console.error('Error al obtener los datos:', error);
          });
      }
    });

    connection.addEventListener('close', () => {
      setupWebSocket();

    });
  };

  useEffect(() => {
    setupOffset();
    const info = setupJwt();
    console.log("info", info)
    setupWebSocket(info);
    let tareas = {}
    fetch(`${url}/tareas/${info.Empresa_id}`, {
      method: 'GET',
      headers: {
        'auth': info.jwt,
        'mode': 'no-cors',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const adjustedData = data["data"].map(tarea => {
          return {
            ...tarea,
            fecha: ISO8601(tarea.fecha),
            fecha_termino: ISO8601(tarea.fecha_termino)
          }
        });
        setTareas(adjustedData);
        setFilteredTareas(adjustedData);
        setLoading(false);
        tareas = adjustedData;
        console.log("tareas after", tareas);
        filterData(tareas);
        filterPieData(tareas);
      });
    let issues = {};
    fetch(`${url}/issues/empresa/${info.Empresa_id}`, {
      method: 'GET',
      headers: {
        'auth': info.jwt, // no-cors, *cors, same-origin
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        setIssues(data);
        setLoading(false);
        issues = data;
        console.log("incidencias", issues);
        filterLineData(issues);
        filterBarData(issues);
      });
    fetch(`${url}/usuarios/empresa/${User_id}`)
      .then(response => response.json())
      .then(data => {
          setEmployees(data.usuarios);
          setEmployee(0);
      })
      .catch(error => {
          console.error('Error al obtener los datos:', error);
      });
  }, []);

  useEffect(() => {
    if (employee === 0 && state === 5 && priority === 5) {
      setFilteredTareas(tareas);
      return;
    } else if (employee !== 0 && state === 5 && priority === 5) {
      const newTasks = tareas.filter(tarea => tarea.User_id === employee);
      setFilteredTareas(newTasks);
      return;
    } else if (employee === 0 && state !== 5 && priority === 5) {
      const newTasks = tareas.filter(tarea => tarea.estado === state);
      setFilteredTareas(newTasks);
      return;
    } else if (employee === 0 && state === 5 && priority !== 5) {
      const newTasks = tareas.filter(tarea => parseInt(tarea.prioridad) === priority);
      setFilteredTareas(newTasks);
      return;
    } else if (employee !== 0 && state !== 5 && priority !== 5) {
      let newTasks = tareas.filter(tarea => tarea.User_id === employee);
      newTasks = newTasks.filter(tarea => tarea.estado === state);
      newTasks = newTasks.filter(tarea => parseInt(tarea.prioridad) === priority);
      setFilteredTareas(newTasks);
      return;
    } else if (employee !== 0 && state !== 5 && priority === 5) {
      let newTasks = tareas.filter(tarea => tarea.User_id === employee);
      newTasks = newTasks.filter(tarea => tarea.estado === state);
      setFilteredTareas(newTasks);
      return;
    } else if (employee !== 0 && state === 5 && priority !== 5) {
      let newTasks = tareas.filter(tarea => tarea.User_id === employee);
      newTasks = newTasks.filter(tarea => parseInt(tarea.prioridad) === priority);
      setFilteredTareas(newTasks);
      return;
    } else if (employee === 0 && state !== 5 && priority !== 5) {
      let newTasks = tareas.filter(tarea => tarea.estado === state);
      newTasks = newTasks.filter(tarea => parseInt(tarea.prioridad) === priority);
      setFilteredTareas(newTasks);
      return;
    }
}, [employee, state, priority]);

  return (
    <div>
      <Helmet>
        <title> Miido </title>
      </Helmet>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Tareas" value={1} />
        <Tab label="Mapa" value={3} />
      </Tabs>
      {tabValue === 1 &&
        <Card sx={{ px: 2, py: 1.5, height: "40vh", display: 'flex', flexDirection: 'column', color: '#3C4447' }}>
          <Typography variant="h4">Dashboard</Typography>
          <Grid container spacing={4} direction="row" style={{ flexGrow: 1 }}>
            <Grid item xs={12} sm={4}>
              {loading ? (
                <LinearProgress />
              ) : (
                <Pie
                  data={pieData}
                  height="100%"
                  width="100%"
                  options={{ maintainAspectRatio: false }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {loading ? (
                <LinearProgress />
              ) : (
                <Line
                  data={lineData}
                  height="100%"
                  width="100%"
                  options={{
                    scales: {
                      x: {
                        grid: {
                          display: false
                        }
                      },
                      y: {
                        grid: {
                          display: false
                        },
                        min: 0,
                        ticks: {
                          callback: function (value) {
                            return Number.isInteger(value) ? value : null;
                          }
                        }
                      }
                    },
                    maintainAspectRatio: false
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
            {loading ? (
                <LinearProgress />
              ) : (
                <Bar
                  data={barData}
                  height="100%"
                  width="100%"
                  options={{
                    scales: {
                      x: {
                        grid: {
                          display: false
                        }
                      },
                      y: {
                        grid: {
                          display: false
                        },
                        ticks: {
                          callback: function (value) {
                            return Number.isInteger(value) ? value : null;
                          }
                        }
                      }
                    },
                    maintainAspectRatio: false,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Card>}
      {tabValue === 2 &&
        <Card sx={{ px: 2, py: 1.5, height: "40vh", display: 'flex', flexDirection: 'column' }}>
          <Grid container spacing={2} direction="row" style={{ flexGrow: 1 }}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h4">Cálculos</Typography>
              <Typography variant="h6">Valor Hora</Typography>
              <Input placeholder="Valor Hora" value={valorHora} onChange={handleValorHora} />
              <Typography variant="h6">Cantidad Horas</Typography>
              <Input placeholder="Cantidad Horas" value={cantidadHoras} onChange={handleCantidadHoras} />
              <Typography variant="h6">Valor Total : {total}</Typography>
              <Button variant="contained" onClick={handleButton}>Agregar Cálculo</Button>
            </Grid>
          </Grid>
        </Card>}
      {tabValue === 1 && (
        <Card sx={{ px: 2.5, py: 2.5 }} style={{ marginTop: '5px' }}>
          <Select sx={{marginRight: 2}} id="employees" value={employee} onChange={e => handleEmployee(e)}>
            <MenuItem value={0}>Todos los empleados</MenuItem>
            {employees.map((employee) => {return <MenuItem key={employee.id} value={employee.id}>{employee.name}</MenuItem>})}
          </Select>
          <Select sx={{marginRight: 2}} id="state" value={state} onChange={e => handleState(e)}>
            <MenuItem key={5} value={5}>Todos los estados</MenuItem>
            <MenuItem key={0} value={0}>Sin comenzar</MenuItem>
            <MenuItem key={1} value={1}>Comenzada</MenuItem>
            <MenuItem key={2} value={2}>Finalizada</MenuItem>
          </Select>
          <Select sx={{marginRight: 2, marginBottom: 2}} id="priority" value={priority} onChange={e => handlePriority(e)}>
            <MenuItem key={5} value={5}>Todas las importancias</MenuItem>
            <MenuItem key={0} value={0}>Sin asignar</MenuItem>
            <MenuItem key={1} value={1}>Bajo</MenuItem>
            <MenuItem key={2} value={2}>Medio</MenuItem>
            <MenuItem key={3} value={3}>Urgente</MenuItem>
          </Select>
          {
            filteredTareas.length > 0 ? (
              <Box sx={{ height: '70vh', overflowY: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sortDirection={orderBy === 'empleado' ? order : false}>
                    <TableSortLabel active={orderBy === 'empleado'} direction={orderBy === 'empleado' ? order : 'asc'} onClick={(e) => handleRequestSort(e, 'empleado')}>
                      Empleado
                      {orderBy === 'empleado' ? (<Box component="span" sx={visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Título</TableCell>
                  <TableCell>
                    <TableSortLabel active={orderBy === 'fecha'} direction={orderBy === 'fecha' ? order : 'asc'} onClick={(e) => handleRequestSort(e, 'fecha')}>
                      Fecha
                      {orderBy === 'fecha' ? (<Box component="span" sx={visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Fecha de termino</TableCell>
                  <TableCell sortDirection={orderBy === 'estado' ? order : false}>
                    <TableSortLabel active={orderBy === 'estado'} direction={orderBy === 'estado' ? order : 'asc'} onClick={(e) => handleRequestSort(e, 'estado')}>
                      Estado
                      {orderBy === 'estado' ? (<Box component="span" sx={visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel active={orderBy === 'prioridad'} direction={orderBy === 'prioridad' ? order : 'asc'} onClick={(e) => handleRequestSort(e, 'prioridad')}>
                      Importancia
                      {orderBy === 'prioridad' ? (<Box component="span" sx={visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Imagen</TableCell>
                  <TableCell>Editar</TableCell>
                  <TableCell>Eliminar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {stableSort(filteredTareas, getComparator(order, orderBy)).map((tarea) => (renderTable([tarea])))}
              </TableBody>
            </Table>
          </Box>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography variant="h3">¡No hay tareas asignadas!</Typography>
          </div>
            ) 
          }
        </Card>
      )}

      {/* Calculos */}
      {tabValue === 2 &&
        <Card sx={{ px: 2.5, py: 2.5 }} >
          <Typography variant="h4">Calculos</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ValorHora</TableCell>
                  <TableCell>cantidadHoras</TableCell>
                  <TableCell>Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {calculos.map((calculo, index) => (
                  <TableRow key={index}>
                    <TableCell>{calculo.valorHora}</TableCell>
                    <TableCell>{calculo.cantidadHoras}</TableCell>
                    <TableCell>{calculo.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      }
      {tabValue === 3 &&
        <Card sx={{ px: 2.5, py: 2.5 }} >
          <Typography variant="h4">Mapa de Operaciones</Typography>
        </Card>
      }
      <Dialog open={open} onClose={handleClose} fullWidth={false}>
        <DialogContent>
                    {selectedImage && <img src={selectedImage} alt="imagen grande" style={{ width: '90vw', height: '85vh', objectFit: 'contain', margin: '0', display: 'block' }} />}
        </DialogContent>
      </Dialog>
    </div>
  );
}