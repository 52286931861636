import { Helmet } from "react-helmet-async";
// @mui
import { Typography, Paper, Box } from "@mui/material";
import { LoginForm } from "../sections/auth/login";
import { LoginButton } from "../sections/auth/login";
import jwtDecode from "jwt-decode";


const CheckToken = () => {

  
  const url = window.location.href;
  const token = url.split("/")[4];
  console.log("token", token);
  const decoded = jwtDecode(token);
  console.log("decoded", decoded);
  if (decoded) {
    localStorage.setItem("jwt", token);
    console.log("Token guardado en local storage");
    console.log("decoded", decoded);
    window.location.href = "/tasks/dashboard";
    return true;
  }
  
}


export default function LoginPageWhatsapp() {

  return (
    <div style={{ background: "#2f3d44", width: "100%", height: "130vh" }}>
      <Helmet>
        <title> Log In </title>
      </Helmet>

      <Box pt={4}>
        <Paper sx={{ width: "420px", m: "auto", p: 4, height: "582px" }}>
          <Box mb={2}>
            { }
            <img
              style={{ margin: "auto", width: "48px" }}
              src="/assets/icons/logoOnLogin.svg"
              alt="SVG as an image"
            />
          </Box>
          <Typography
            variant="h5"
            color="lightgray"
            sx={{ textAlign: "center" }}
          >
            Estamos cargando tu información ...
          </Typography>
          <br />
          <Typography
            variant="h4"
            gutterBottom
            textAlign={"center"}
            sx={{ fontSize: "24px" }}
          >
            Bienvenido Miido
          </Typography>
          {CheckToken()}
          
        </Paper>
      </Box>
    </div>
  );
}