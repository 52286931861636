import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/wefleet/Table'
import Botones from '../../components/wefleet/Botones'

import { useState, useEffect, useRef } from 'react'
import { read, utils } from 'xlsx'
import wefleetUser from '../../models/wefleetUser'
import dotenv from 'dotenv'
dotenv.config()

const URL_BACKEND = process.env.REACT_APP_BASE_URL



export default function Fleeters({ empleados, setEmpleados }) {
  const fileInputRef = useRef(null)
  const generateId = () => {
    return Math.floor(Math.random() * 1000000)
  }

  const handleCargarExcel = (event) => {
    const file = event.target.files[0]; // Obtener el archivo cargado
    const reader = new FileReader(); // Crear un lector de archivos

    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result); // Leer el contenido del archivo
      const workbook = read(data, { type: 'array' }); // Leer el archivo como un libro de trabajo

      const sheetName = workbook.SheetNames[0]; // Obtener el nombre de la primera hoja
      const worksheet = workbook.Sheets[sheetName]; // Obtener la hoja de trabajo

      const jsonData = utils.sheet_to_json(worksheet, { header: 1 }); // Convertir la hoja en JSON


      const headerRow = jsonData[0]; // Obtener la primera fila como encabezados
      const empleadoObj = jsonData.slice(1).map((row) => {
        // Convertir las filas a una instancia de wefleetUser, usando la primera fila como encabezados
        return headerRow.reduce((acc, key, i) => {
          acc[key] = row[i]; // Agrega el par clave-valor al objeto

          return acc;
        }, {});
      });
      console.log(empleadoObj);
      fetch(`${URL_BACKEND}/wefleet/users/post`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(empleadoObj)
      })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
        })
        .catch((error) => {
          console.error('Error:', error);
        }
        );
      // Actualizar el estado con los datos procesados
      fetch(`${URL_BACKEND}/wefleet/users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
      
          setEmpleados(data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    
    //console.log(jsonData); // Mostrar los datos en consola (puedes procesar o mostrar los datos como prefieras)
  };

  reader.readAsArrayBuffer(file); // Leer el archivo como un ArrayBuffer
}
// const handleCargarExcel = (event) => {
//   const file = event.target.files[0];
//   if (file) {
//     const reader = new FileReader();
//     reader.onload = (e) => {
//       const data = new Uint8Array(e.target.result);
//       const workbook = read(data, { type: 'array' });
//       const sheetName = workbook.SheetNames[0]; // Usar la primera hoja
//       const worksheet = workbook.Sheets[sheetName];

//       // Convertir la hoja de Excel a JSON
//       let jsonData = utils.sheet_to_json(worksheet, { header: 1 });

//       // Rellenar y eliminar columnas 'Unnamed'
//       jsonData = jsonData.map(row => row.map((cell, index) => cell || row[index - 1] || '')); // Rellena vacíos
//       jsonData = jsonData.filter(row => row.some(cell => cell !== null && cell !== '')); // Elimina filas vacías

//       // Filtrar columnas 'Unnamed'
//       const headerRow = jsonData[0];
//       const filteredIndexes = headerRow.map((header, index) => header && !header.toLowerCase().includes('unnamed') ? index : -1).filter(index => index !== -1);
//       const cleanedData = jsonData.map(row => filteredIndexes.map(index => row[index])); // Filtra columnas 'Unnamed'

//       console.log(cleanedData);
//       // Actualizar el estado con los datos procesados
//       const empleadosData = cleanedData.slice(1).map((row) => {
//         // Convertir las filas a una instancia de wefleetUser, usando la primera fila como encabezados
//         const empleadoObj = headerRow.reduce((acc, key, i) => {
//           if (filteredIndexes.includes(i)) {  // Verifica si el índice está en los índices filtrados
//             acc[key] = row[i]; // Agrega el par clave-valor al objeto
//           }
//           return acc;
//         }, {});
//         const tiempo = new Date().toLocaleString();
//         if (empleadoObj.Rut !== undefined) {
//           return new wefleetUser(
//             generateId(),
//             empleadoObj['RUT'],
//             empleadoObj['Primer nombre'] + " " + empleadoObj['Segundo nombre'],
//             empleadoObj['Telefono'],
//             empleadoObj['Correo electronico'],
//             "",
//             empleadoObj['Vehiculo'],
//             empleadoObj['Horario'],
//             "",
//             empleadoObj['Region'],
//             empleadoObj['Patente de vehiculo'],
//             {tiempo: tiempo, tipo:"Subido recientemente"},
//             "No enviado",
//             ""
//           );
//         } else {
//           return null; // Retorna null si Rut es undefined
//         }
//       }).filter(empleado => empleado !== null); // Filtra los valores nulos
//       console.log(empleadosData);
//       setEmpleados(empleadosData);
//       fetch(`${URL_BACKEND}/wefleet/users/post`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json'
//           },
//           body: JSON.stringify(empleadosData)
//         })
//           .then(response => response.json())
//           .then(data => {
//             console.log('Success:', data);
//           })
//           .catch((error) => {
//             console.error('Error:', error);
//           }
//             );
//     };
//     reader.readAsArrayBuffer(file);
//   }


// };

const handleDescargar = () => {
  console.log("Descargando...");
};



return (
  <div className="flex flex-col">
    <div className="flex justify-between items-center mb-6">
      <h1 className="text-2xl font-bold">Fleeters</h1>
      <Botones
        handleCargarExcel={handleCargarExcel}
        handleDescargar={handleDescargar}
        fileInputRef={fileInputRef}
      />
    </div>

    <div className="overflow-x-auto relative z-10 bg-white shadow-md rounded-lg">
      <div className="max-h-[600px] overflow-y-auto">
        <Table>
          <TableHeader className="sticky top-0 bg-white z-10">
            <TableRow>
              <TableHead className="bg-white">Nombre</TableHead>
              <TableHead className="bg-white">Teléfono</TableHead>
              <TableHead className="bg-white">Email</TableHead>
              {/* <TableHead className="bg-white">Última Ruta</TableHead> */}
              <TableHead className="bg-white">Vehículo</TableHead>
              <TableHead className="bg-white">Patente</TableHead>
              <TableHead className="bg-white">Turno</TableHead>
              <TableHead className="bg-white">Operación</TableHead>
              <TableHead className="bg-white">Región</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {empleados.map((empleado) => (
              console.log(empleado),
              <TableRow key={empleado.id}>
                <TableCell>{empleado.nombre}</TableCell>
                <TableCell>{empleado.telefono}</TableCell>
                <TableCell>{empleado.email}</TableCell>
                {/* <TableCell>{empleado.ultimaRuta}</TableCell> */}
                <TableCell>{empleado.vehiculo}</TableCell>
                <TableCell>{empleado.patente}</TableCell>
                <TableCell>{empleado.turno}</TableCell>
                <TableCell>{empleado.operacion_usuario}</TableCell>
                <TableCell>{empleado.region}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  </div>

);

}

