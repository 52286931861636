
// @mui
import {
  Grid,
  Card,
  Button,
  TableHead,
  TableRow,
  TableCell,
  Box,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import Table from '@mui/material/Table';
import TableContainer from '@material-ui/core/TableContainer';

import LinearProgress from '@mui/material/LinearProgress';
// components
import { TitleTracker } from "src/App";
// sections
import React from "react";
import { useEffect, useState, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  FeatureGroup
} from 'react-leaflet';

import CustomPolygon from '../components/polygon';
import { makeStyles } from '@mui/styles';
import jwtDecode from "jwt-decode";



// --------------------- table ----------------------
const useStyles = makeStyles({
  bar: {
    backgroundColor: '#51A09A', // Cambia el color de la barra aquí
  },
});

const Map = ({ listaUnidades, center, onSelectedUnidad }) => {

  const mapRef = useRef(null);
  // TODO: mostrar en el mapa cual unidad está seleccionada
  const [getSelectedPolygons, setSelectedPolygons] = useState([]);
  const [getOverMousePolygon, setOverMousePolygon] = useState({});

  // const latitude = -33.666118;
  // const longitude = -70.549456;

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.flyTo([center.latitude, center.longitude], 14);
    }
  }, [center])

  const handlerPolygonHover = (unidad) => {
    setOverMousePolygon(unidad);
  }

  const handlerPolygonMouseOut = (unidad) => {
    setOverMousePolygon({});
  }


  return (
    <MapContainer
      center={[center.latitude, center.longitude]}
      zoom={13}
      style={{ width: "100%", height: "96%", borderRadius: "6px" }}
      ref={mapRef}
    >
      <TileLayer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
        attribution="MIIDO"

      />
      {listaUnidades.map((unidad) => (
        <FeatureGroup key={unidad.id} >
          <CustomPolygon
            index={unidad.id}
            unidad={unidad}
            onClick={() => {
              onSelectedUnidad(unidad);
            }}
            setMouseOver={handlerPolygonHover}
            setMouseOut={handlerPolygonMouseOut}
            selected={getSelectedPolygons.includes(unidad.id)}
          />
        </FeatureGroup>
      ))}


    </MapContainer>
  )
}



// ----------------------------------------------------------------------


const cuartelesMap = [
  {
    "id": 1,
    "nombre": "Cuartel 25",
    "superficie": 10.37,
    "zona": 1,
    "coordenadas": [
      [-33.663721, -70.552011],
      [-33.668636, -70.551375],
      [-33.668485, -70.549437],
      [-33.663318, -70.550133],


    ],
  },
  {
    "id": 2,
    "nombre": "Cuartel 26",
    "superficie": 5.93,
    "zona": 2,
    "coordenadas": [
      [-33.663261, -70.549980], // Punto 1 arriba izq
      [-33.662924, -70.547925], // Punto 2 arriva der
      [-33.668474, -70.547186],
      [-33.668527, -70.549221],
    ],
  },

]

const sectoresMap = [
  {
    "id": 1,
    "superficie": 5.36,
    "nombre": "Sector 8",
    "cuartel": 1,
    "coordenadas": [
      [-33.672122, -70.546279],
      [-33.672198, -70.544776],
      [-33.674445, -70.544807],
      [-33.674370, -70.546400]
    ],
  },
  {
    "id": 2,
    "nombre": "Sector 9",
    "superficie": 5.01,
    "cuartel": 1,
    "coordenadas": [
      [-33.672248, -70.543153],
      [-33.672198, -70.544776],
      [-33.674445, -70.544807],
      [-33.674509, -70.543335]
    ],
  },
  {
    "id": 3,
    "nombre": "Sector 8",
    "superficie": 3.12,
    "cuartel": 2,
    "coordenadas": [
      [-33.674471, -70.546354],
      [-33.675986, -70.546461],
      [-33.676137, -70.543502],
      [-33.674673, -70.543320]
    ],
  },
  {
    "id": 4,
    "nombre": "Sector 10",
    "cuartel": 2,
    "superficie": 2.80987,
    "coordenadas": [
      [-33.675986, -70.546461],
      [-33.676137, -70.543502],
      [-33.677893, -70.543608],
      [-33.677842, -70.546506]
    ],
  },

]
const latitude = -33.665889;
const longitude = -70.549800;

const url = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem('jwt');
const headers = {
  auth: token,
  'Content-Type': 'application/json',
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
};

function fetchCuarteles() {
  const userId = localStorage.getItem('user_id');
  return fetch(`${url}/cuarteles/user/${userId}`, {
    headers: headers,
    method: 'GET',
    mode: 'cors',
  })
  .then(response => response.json())
  .then(data => {
    return data.data;
  })
  
}


export default function DashboardAppPage() {

  const [selectedUnits, setSelectedUnits] = useState(cuartelesMap);
  const [getSelectedPolygons, setSelectedPolygons] = useState([1, 2]);
  const [getOverMousePolygon, setOverMousePolygon] = useState({});
  const [selectedMapButton, setSelectedMapButton] = useState(6);
  const [center, setCenter] = useState({ latitude: -33.666118, longitude: -70.549456 });
  const [currentUnit, setCurrentUnit] = useState({});
  const [productos, setProductos] = useState([]);
  const mapRef = React.useRef();
  const { setTitleOnNav } = React.useContext(TitleTracker);
  const classes = useStyles();

  React.useLayoutEffect(() => {
    setTitleOnNav("Tu panel");
  }, []);
  const handleSelectedUnidad = (unidad) => {
    setCurrentUnit(unidad);
  };

  const handlerPolygonHover = (unidad) => {
    setOverMousePolygon(unidad);
  };

  const handlerPolygonClick = (e) => {
    const index = e.target.options.id;
    console.log("index", index);
    setCurrentUnit()


    // if (getSelectedPolygons.includes(index)) {
    //   setSelectedPolygons([...getSelectedPolygons.filter(val => val !== index)]);
    // } else {
    //   setSelectedPolygons([...getSelectedPolygons, index]);
    // }
  }

  const fetchData = () => {
    if (currentUnit.id) {
      fetch(`${url}/calendario_fertilizacion/get/cuartel/${currentUnit.id}`, {
        headers: headers,
        method: 'GET',
        mode: 'cors',
      })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            console.log("productos", data.productos)
            const months = ["Octubre", "Noviembre", "Diciembre", "Enero", "Febrero", "Marzo"];
            let productos = data.productos
            productos.sort((a, b) => months.indexOf(a.month) - months.indexOf(b.month));
            setProductos(productos)
        })
    }
  }
  /* useEffect(() => {
    fetchCuarteles().then((data) => {
      console.log("data", data[0].coordenadas);
      setSelectedUnits(data);
    });
  }, []); */
  
  useEffect(() => {
    fetchData(); // Llama a la función cuando el componente se monta
  
    const interval = setInterval(() => {
      fetchData(); // Llama a la función cada 10 segundos
    }, 3000);
  
    return () => clearInterval(interval);
  }, [currentUnit]);


  return (
    <div>
      <Helmet>
        <title> Miido </title>
      </Helmet>
      <Card sx={{ px: 2, py: 1.5 }} style={{ height: "60vh" }}>
        <Grid container spacing={1}>
          {/* <Button
            variant={'contained'}
            sx={{ color: 'white' }}
            style={{
              margin: '0 5px',
              backgroundColor: selectedMapButton === 1 ? 'rgba(81, 160, 154, 1)' : 'rgba(81, 160, 154, 0.7)',
            }}
            onClick={() => {
              setSelectedMapButton(1);
              console.log("Variedad")
            }}
          >
            Variedad
          </Button>
          <Button
            variant={'contained'}
            sx={{ color: 'white' }}
            style={{
              margin: '0 5px',
              backgroundColor: selectedMapButton === 2 ? 'rgba(81, 160, 154, 1)' : 'rgba(81, 160, 154, 0.7)',
            }}
            onClick={() => {
              setSelectedMapButton(2);
              console.log("Sist. Conducción")
            }}
          >
            Sist. Conducción
          </Button>
          <Button
            variant={'contained'}
            sx={{ color: 'white' }}
            style={{
              margin: '0 5px',
              backgroundColor: selectedMapButton === 3 ? 'rgba(81, 160, 154, 1)' : 'rgba(81, 160, 154, 0.7)',
            }}
            onClick={() => {
              setSelectedMapButton(3);
              console.log("Aptitud");
            }}
          >
            Aptitud
          </Button>
          <Button
            variant={'contained'}
            sx={{ color: 'white', fontSize: '12px' }}
            style={{
              margin: '0.5px',
              backgroundColor: selectedMapButton === 4 ? 'rgba(81, 160, 154, 1)' : 'rgba(81, 160, 154, 0.7)',
            }}
            onClick={() => {
              setSelectedMapButton(4);
              console.log("Valle")
            }}
          >
            Valle
          </Button>
          <Button
            variant={'contained'}
            sx={{ color: 'white' }}
            style={{
              margin: '0 5px',
              backgroundColor: selectedMapButton === 5 ? 'rgba(81, 160, 154, 1)' : 'rgba(81, 160, 154, 0.7)',
            }}
            onClick={() => {
              setSelectedMapButton(5);
              setSelectedPolygons([]);
              setUnidades(sectoresMap);
            }}
          >
            Sectores
          </Button> */}

          <Button
            variant={'contained'}
            sx={{ color: 'white' }}
            style={{
              margin: '0 5px',
              backgroundColor: selectedMapButton === 6 ? 'rgba(81, 160, 154, 1)' : 'rgba(81, 160, 154, 0.7)',
            }}
            onClick={() => {
              setSelectedMapButton(6);
              setSelectedPolygons([]);

            }}
          >
            Cuarteles
          </Button>
          <Button
            variant={'contained'}
            sx={{ color: 'white' }}
            style={{
              margin: '0 5px',
              backgroundColor: 'rgba(81, 160, 154, 1)',
            }}
            onClick={() => {
              const map = mapRef.current;
              setSelectedMapButton(7);
              if (map) {
                map.flyTo([latitude, longitude], 14);
              }
            }}
          >
            Centrar
          </Button>
          <Button
            disabled
            variant={'contained'}
            sx={{ color: 'white' }}
            // style={{
            //   marginRight: '0.5 px',
            //   backgroundColor: selectedMapButton === 8 ? 'rgba(81, 160, 154, 1)' : 'rgba(81, 160, 154, 0.7)',
            // }}
            onClick={() => {
              setSelectedMapButton(8);
              console.log("nuevo campo")
            }}
          >
            Agregar campo nuevo
          </Button>

          <Button
            variant={'contained'}
            sx={{ color: 'white' }}
            style={{
              margin: '0 5px',
              backgroundColor: 'rgba(81, 160, 154, 1)',
            }}
            onClick={() => {
              window.location.href = "/dashboard/CalculationPage";
            }}
          >
            Crear nuevo programa
          </Button>
          <Button
            variant={'contained'}
            sx={{ color: 'white' }}
            style={{
              margin: '0 5px',
              backgroundColor: 'rgba(81, 160, 154, 1)',
            }}
            onClick={() => {
              window.location.href = `/dashboard/Aplicación/${currentUnit.id}`;
            }}
          >
            Ir a calendario
          </Button>
        </Grid>
        <Grid container style={{ height: '100%' }}>
            <Map
              listaUnidades={selectedUnits}
              center={center}
              onSelectedUnidad={handleSelectedUnidad}
            ></Map>
        </Grid>


      </Card>
      <Card sx={{ px: 2.5, py: 2.5 }} /* style={{ height: "60vh", overflow: 'auto'}} */>
      <TableContainer sx={{ maxHeight: 440 }}>
      <Table stickyHeader  >
          <TableHead>


            <tr>
              <th>Unidad</th>
              {/* <th>Rendimiento</th> */}
              <th>Mes aplicación</th>
              <th>Costos/Kilo</th>
              <th>F. Aplicado</th>

              {/* <th>Tasa Crecimiento</th>
              <th>Eficiencia fertilizante</th>
              <th>Indice abs. de Nutrientes</th> */}
            </tr>

            {/*
               <TableRow>
              <TableCell>Sectores de riego</TableCell>
              <TableCell>Rendimiento</TableCell>
              <TableCell>Costos/Kilo</TableCell>
              <TableCell>F. Aplicado</TableCell>
              <TableCell>Tasa Crecimiento</TableCell>
              <TableCell>Eficiencia fertilizante</TableCell>
              <TableCell>Indice abs. de Nutrientes</TableCell>
              </TableRow>*/}

          </TableHead>
          <TableRow>
            <TableCell><strong>{currentUnit.nombre}</strong></TableCell>
          </TableRow>
          {productos.length > 0 ?
            productos.map((producto, index) => (
              <TableRow>
                <TableCell>{producto.product.Nombre}</TableCell>
                {/* <TableCell>{unidad.rendimiento}%</TableCell> */}
                <TableCell>{producto.month}</TableCell>
                <TableCell>${(producto.product.precio)/(producto.product.CapacidadEnvase)}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2" color="text.secondary">{`${Math.round(
                        producto.fulfilled,
                      )}%`}</Typography>
                    </Box>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <LinearProgress variant="determinate"
                        value={producto.fulfilled}
                        classes={{ bar: classes.bar }} />
                    </Box>

                  </Box>
                </TableCell>
                {/* <TableCell> {unidad.tasa} </TableCell>
                <TableCell> {unidad.rendimiento}% </TableCell>
                <TableCell> {unidad.indice} </TableCell> */}

              </TableRow>
            )) :
            selectedUnits.length === 0 ? <div> Seleccione un cuartel</div> :

            selectedUnits.filter((unidad) => selectedUnits.includes(unidad.cuartel)).map((unidad, index) => (

                <TableRow>
                  <TableCell>{unidad.nombre}</TableCell>
                  {/* <TableCell>{unidad.rendimiento}%</TableCell> */}
                  <TableCell>34</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography variant="body2" color="text.secondary">{`${Math.round(
                          unidad.aplicado,
                        )}%`}</Typography>
                      </Box>
                      <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress variant="determinate"
                          sx={{ height: 10 }}
                          value={unidad.aplicado}
                          classes={{ bar: classes.bar }} />
                      </Box>

                    </Box>
                  </TableCell>
                  {/* <TableCell> {unidad.tasa} </TableCell>
                  <TableCell> {unidad.rendimiento}% </TableCell>
                  <TableCell> {unidad.indice} </TableCell> */}

                </TableRow>
              ))


          }



        </Table>
      </TableContainer>
        
      </Card>
    </div>
  );
}
