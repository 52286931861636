import { Card, Box, Typography, Table, TableCell, TableBody, TableRow, TableHead, Select, MenuItem, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useEffect, useState, Fragment } from 'react';
import dotenv from 'dotenv';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

dotenv.config();
const url = process.env.REACT_APP_BASE_URL;

export default function FormularioLCP() {
    if (!localStorage.getItem('jwt')) {
        window.location.href = "/login";
    }
    const [employees, setEmployees] = useState([]);
    const [employee, setEmployee] = useState({});
    const [formularios, setFormularios] = useState([]);
    const [filteredFormularios, setFilteredFormularios] = useState([]);
    const [expanded, setExpanded] = useState(false); // Estado para rastrear el Accordion expandido
    const jwt = localStorage.getItem('jwt');
    const data = jwt.split('.')[1];
    const user = JSON.parse(atob(data));
    const Empresa_id = user.Empresa_id;
    const User_id = user.User_id;

    function setupWebSocket() {
        const uri = 'wss://websocket-eqjlrpwjoa-uc.a.run.app';
        const connection = new WebSocket(uri);
        connection.addEventListener('open', () => {
            console.log('Connected to the WebSocket server');
        });

        connection.addEventListener('message', (event) => {
            const data = event.data;
            console.log('Received the defined message:', data);
            if (data === 'refresh formularios') {
                fetch(`${url}/issues/empresa/${Empresa_id}`)
                    .then(response => response.json())
                    .then(data => {
                        console.log(data);
                        setFormularios(data);
                        setFilteredFormularios(data);
                    })
                    .catch(error => {
                        console.error('Error al obtener los datos:', error);
                    });
            }
        });

        connection.addEventListener('close', () => {
            setupWebSocket();
        });
    }

    const handleEmployee = (e) => {
        if (e.target.value === 0) {
            setEmployee(0);
            setFilteredFormularios(formularios);
            return;
        }
        setEmployee(e.target.value);
    }

    useEffect(() => {
        setupWebSocket();
        fetch(`${url}/usuarios/empresa/${User_id}`)
            .then(response => response.json())
            .then(data => {
                setEmployees(data.usuarios);
                setEmployee(0);
            })
            .catch(error => {
                console.error('Error al obtener los datos:', error);
            });
        fetch(`${url}/formularios/empresa/${Empresa_id}`)
            .then(response => response.json())
            .then(data => {
                setFormularios(data.data);
            })
            .catch(error => {
                console.error('Error al obtener los datos:', error);
            });
    }, []);

    useEffect(() => {
        if (employee === 0) {
            setFilteredFormularios(formularios);
            return;
        }
        const newFormularios = formularios.filter(issue => issue.User_id === employee);
        setFilteredFormularios(newFormularios);
    }, [employee]);

    const renderTable = (formularios) => {
        return formularios.map((formulario) => {
            let sum = 1;
            const isExpanded = expanded === formulario.id;
            return (
                <TableRow key={formulario.id}>
                    <TableCell>{formulario.empleado}</TableCell>
                    <TableCell>{formulario.empresa}</TableCell>
                    <TableCell sx={{ width: '30%' }}>
                        <Accordion expanded={isExpanded} onChange={() => handleAccordionChange(formulario.id)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                {!isExpanded && "(click para más detalles)"}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {fixData(formulario.data).map((line, index) => {
                                        sum = sum * line.value;
                                        return(
                                            <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>{line.text}</span>
                                                <span>{line.value}</span>
                                            </Box>
                                        )
                                    })}
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Total:</span>
                                        <span>{sum}</span>
                                    </Box>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </TableCell>
                    <TableCell>{formulario.tipo}</TableCell>
                    <TableCell>{fixDate(formulario.fecha)}</TableCell>
                </TableRow>
            );
        });
    }

    const handleAccordionChange = (panel) => {
        setExpanded(expanded === panel ? false : panel);
    };

    const fixDate = (oldDate) => {
        const date = new Date(oldDate);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const fixData = (data) => {
        const newData = data.replace(/_/g, ' ');
        const replacedData = newData.replace(/'/g, '"');
        const parsedData = JSON.parse(replacedData);
        let fixedData = [];
        for (const key in parsedData) {
            if (key === 'tipo') continue;
            fixedData.push({ text: key + ":", value: parsedData[key] });
        }
        return fixedData;
    }

    return (
        <Card sx={{ px: 2, py: 1.5, height: "95%" }}>
            <Box sx={{ p: 3, display: 'flex', flexDirection: 'row' }} justifyContent="space-between" >
                <Typography variant="h4" sx={{ mb: 3 }}>
                    Formularios
                </Typography>
                <Select
                    value={employee}
                    onChange={e => handleEmployee(e)}
                >
                    <MenuItem value={0}>Todos</MenuItem>
                    {employees.map((employee) => {
                        return <MenuItem key={employee.id} value={employee.id}>{employee.name}</MenuItem>
                    })}
                </Select>
            </Box>
            {
                filteredFormularios.length > 0 ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Empleado</TableCell>
                                <TableCell>Empresa</TableCell>
                                <TableCell sx={{ width: '30%' }}>Data</TableCell>
                                <TableCell>Tipo</TableCell>
                                <TableCell>Fecha</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderTable(filteredFormularios)}
                        </TableBody>
                    </Table>
                    :
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Typography variant="h3">No hay formularios subidos</Typography>
                    </div>
            }
        </Card>
    )
}
