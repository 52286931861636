export function Checkbox({ checked, onChange, label }) {
    return (
      <label className="flex items-center">
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          className="form-checkbox h-4 w-4 text-black rounded focus:ring-2 focus:ring-black"
        />
        <span className="ml-2 text-sm">{label}</span>
      </label>
    );
  }