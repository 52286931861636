// components/Register.js

import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { GripVertical } from 'lucide-react'

const Register = ({ register, index }) => {
  return (
    <Draggable key={register.id} draggableId={register.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`bg-gray-50 p-3 rounded-lg ${snapshot.isDragging ? 'border-2 border-blue-500' : ''}`}
        >
          <div {...provided.dragHandleProps} className="cursor-move">
            <GripVertical className="w-4 h-4 mb-2 text-gray-400" />
          </div>
          <div className="space-y-1">
            <div><strong>Task:</strong> {register.task}</div>
            <div><strong>Status:</strong> {register.status}</div>
            <div><strong>Person:</strong> {register.person}</div>
            <div><strong>Start Date:</strong> {register.startDate}</div>
            <div><strong>End Date:</strong> {
              register.startDate === register.endDate ? 
              <span className="text-gray-500">No end date</span> : 
              register.endDate
            }</div>
            <div><strong>Priority:</strong> {register.priority}</div>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default Register